import React from "react";
import { twMerge } from "tailwind-merge";
import { Tag } from "./utilityComps.js";
import { useMetaMaskContext } from "../wrappers/MetaMaskWrapper.js";
import { useAppContext } from "../App.js";
import { useAuthContext } from "../wrappers/AuthWrapper.js";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faBank,
  faGear,
  faPerson,
  faSignOut,
  faUser,
  faVault,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { motion } from "framer-motion";
import { getv } from "../utils/utils.js";
import { NavLogin } from "../wrappers/AuthWrapper.js";

const Logo = () => {
  return (
    <div className="fr-cc">
      <div className="w-[4rem] h-[4rem] ml-4 p-2">
        <img
          className="h-full object-contain"
          src="https://i.imgur.com/ngmPy1x.png"
          alt=""
        />
      </div>
      <div className="fc-ss">
        <span className="text-red-400 font-digi text-[0.8rem]"> Admin</span>
        <span className="text-acc0 font-digi text-[1.2rem]"> FBike</span>
      </div>
    </div>
  );
};

const AccountDrop = () => {
  const aucon = useAuthContext();
  const { vault } = aucon;

  const vault_name = getv(aucon, "vaultdoc.vault_name");

  const [hover, set_hover] = useState(false);

  const linkcn = "text-white hover:text-acc0 py-1 px-2 text-[1.2rem]";
  const options = [
    [null, "Logout", twMerge(linkcn, "text-red-300"), aucon.logout, faSignOut],
  ];

  return (
    <div onMouseLeave={() => set_hover(false)}>
      <div
        onMouseEnter={() => set_hover(true)}
        className="fr-cc mx-4 gap-1 relative"
      >
        <div className=" w-[3rem] h-[3rem] bg-dark fc-cc rounded-full">
          <FontAwesomeIcon className="text-acc0 " icon={faUser} />
        </div>
        <FontAwesomeIcon className="text-acc0 " icon={faAngleDown} />
      </div>
      <motion.div
        variants={{
          hidden: { opacity: 0, height: 0, pointerEvents: "none" },
          visible: {
            opacity: 1,
            height: "auto",
            pointerEvents: "auto",
          },
        }}
        animate={hover ? "visible" : "hidden"}
        className="absolute min-w-[15rem] w-[15rem] bg-reg border border-dark top-[3rem] right-0 rounded-md p-4"
      >
        <p className="font-digi text-acc0 py-1 px-2 mb-2 text-[1.2rem]">
          {vault_name}
        </p>
        <hr />
        {options.map(([to, txt, cn, fn, ico]) => {
          return (
            <Link
              onClick={() => {
                if (fn) fn();
              }}
              className={twMerge("fr-sc gap-2", cn)}
              to={to}
            >
              <span>
                <FontAwesomeIcon icon={ico} />
              </span>
              <span>{txt}</span>
            </Link>
          );
        })}
      </motion.div>
    </div>
  );
};

const Nav = () => {
  const { path, history } = useAppContext();
  const mmcon = useMetaMaskContext();
  const aucon = useAuthContext();

  const links = [
    [`/racecounts`, "Races"],
    [`/customrace`, "Custom Race"],
    [`/jackpot`, "JackPot"],
    [`/daily-earnings`, "Daily Earnings"],
    [`/game-flow`, "Game Flow"],
    [`/actions`, "Actions"],
    [`/finmov`, "FinMov"],
    ["/fqual", "FQual"],
    ["/challenge", "Challenge"],
    ["/factions_missions_list", "Fc.Missions"],
    ["/factions_bingos", "Fc.Bingos"],
    ["/rally", "Rally"],
    ["/vault_stats", "Vault Stats"],
    ["/inbox", "Inbox"],
    ["/funds", "Funds"],
    ["/hstats", "H_Stats"],
    ["/bulk-race-payouts", "bulk-race-payouts"],
  ];

  const linkcn = "hover:text-acc0 font-mon px-2 resp-text--2 fc-cc h-full";
  const smlinkcn = "hover:text-acc0 font-mon px-2 text-[0.8rem] fc-cc h-full";
  const activecn = "border-b border-acc0 text-acc0";

  return (
    <div
      className={twMerge(
        " w-[100vw]",
        path == "/" ? "absolute h-0 top-0 z-10" : "",
      )}
    >
      <div
        className={twMerge(
          "h-[4rem] bg-reg flex flex-row justify-center items-center gap-2",
          path == "/" ? "bg-opacity-40" : "",
        )}
      >
        <Logo />
        {aucon.auth == true && (
          <>
            <div className="w-[4rem]"></div>
            {links.map(([to, txt]) => {
              return (
                <Link
                  key={to}
                  to={to}
                  className={twMerge(linkcn, path == to ? activecn : "")}
                >
                  <span>{txt}</span>
                </Link>
              );
            })}
            <div className="flex-1"></div>

            <AccountDrop />
          </>
        )}
        {aucon.auth == false && (
          <>
            <div className="flex-1"></div>
            <NavLogin />
          </>
        )}
      </div>
    </div>
  );
};

function Layout(props) {
  return (
    <div className="relative">
      <Nav />
      <main
        className={
          twMerge()
          // "md:max-w-[80rem] md:w-full mx-auto",
          // "xs:max-w-[95vw] mx-auto"
        }
      >
        {props.children}
      </main>
    </div>
  );
}

export default Layout;
