import React from "react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useQueries } from "react-query";
import {
  q_admin_fqual_delete,
  q_admin_fqual_doc,
  q_admin_fqual_leader,
  q_admin_fqual_list,
  q_admin_fqual_presets_save,
  q_admin_fqual_update,
  q_admin_fqual_presets_list,
  qiserr,
  qissuccesss,
  useStepQuery,
  q_admin_fqual_presets_get,
  q_admin_fqual_presets_delete,
  q_admin_fqual_entrants,
  polytxnidlink,
  q_admin_fqual_add_entrant,
  q_admin_fqual_delete_entrant,
  q_admin_fqual_delete_finale,
  q_fqual_updatesheet,
} from "../queries/queries.js";
import {
  base64_to_json,
  cdelay,
  copy_to_sheet_table,
  dec,
  getv,
  iso,
  iso_format,
  json_to_base64,
  jstr,
  nils,
  set_inp,
} from "../utils/utils.js";
import {
  gen_filters_from_valob,
  gen_valob_from_filters,
} from "../utils/filt.js";
import { tokdecn2, useAppContext } from "../App.js";
import { Loader01c } from "../components/anims.js";
import _, { set } from "lodash";
import { Card, Img, InpText, Tag } from "../components/utilityComps.js";
import {
  DateInput,
  InpAutoWrap,
  extract_inp,
  set_state_ob,
} from "../components/input.js";
import { twMerge } from "tailwind-merge";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { pos_txt, tablecn } from "../utils/cn_map.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBox,
  faCheckSquare,
  faClose,
  faFlagCheckered,
  faLink,
  faPlus,
  faRefresh,
  faSave,
  faSquare,
  faSync,
  faThumbsDown,
  faThumbsUp,
  faTrash,
  faUsd,
} from "@fortawesome/free-solid-svg-icons";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import { polychainimg } from "../utils/links.js";
import { ErrorBoundary } from "../components/errbou.js";

const FqualContext = createContext();
export const useFqualContext = () => useContext(FqualContext);

export const FqualListPage = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;
  const bfilt = useMemo(() => {
    let f = psearch.f;
    if (nils(f)) return {};
    return base64_to_json(f);
  }, [psearch]);

  const [filt, set_filt] = useState({
    date: {
      label: "Date",
      vals: bfilt?.date ?? { mi: null, mx: null },
      type: "date-range",
    },
    status: {
      label: "Status",
      vals: !_.isEmpty(bfilt.status) ? bfilt.status : [],
      type: "options",
      cn: "resp-text--2 font-digi bg-acc0/10 text-white",
      active_cn: (active, op) => (active ? "bg-acc0/50" : ""),
      options: [
        "yettostart",
        "qualifying",
        "scheduled",
        "running",
        "ending",
        "ended",
      ],
    },
  });

  const [searchtxt, set_searchtxt] = useState("");
  const valfilt = useMemo(() => {
    let o = gen_valob_from_filters(filt);
    return o;
  }, [jstr(filt)]);
  useMemo(() => {
    upd_psearch({ f: json_to_base64(valfilt) });
  }, [searchtxt, jstr(valfilt)]);

  const [qo_flist] = useQueries([q_admin_fqual_list({ filt: valfilt })]);
  const fs = useMemo(() => {
    if (!qissuccesss(qo_flist)) return [];
    return getv(qo_flist, "data.result") || [];
  }, [qo_flist.dataUpdatedAt]);
  const fids = useMemo(() => _.map(fs, "fid"), [jstr(fs)]);
  const viewfids = useMemo(() => {
    if (nils(searchtxt)) return _.map(fs, "fid");
    return _.chain(fs)
      .filter((e) => {
        if (nils(e.name)) return false;
        let n = e.name.toLowerCase();
        return n.includes(searchtxt.toLowerCase());
      })
      .map("fid")
      .value();
  }, [jstr(fs), searchtxt]);

  const qost_fquals = useStepQuery({
    key: "qost_fquals",
    par_ar: viewfids.map((e) => [{ fid: e }]),
    q_: q_admin_fqual_doc,
    params: { valfilt },
    lim: 2,
  });

  const inpargs = { filters: filt, set_filters: set_filt, fkey: "fquallist" };
  const clear_filt = () => {
    let nfilt = gen_filters_from_valob(filt, {});
    set_filt(nfilt);
  };

  return (
    <div className="h-page">
      <Helmet>
        <title>FQuals || Admin</title>
      </Helmet>
      <div className="max-w-[98vw] w-[60rem] mx-auto">
        <div className="h-[5rem]"></div>
        <p className="text-center resp-text-2 text-acc0 font-digi">FQuals</p>
        <div className="h-[1rem]"></div>

        <Card className="bg-reg w-full">
          <InpAutoWrap {...{ ...inpargs, idd: "date" }} />
          <InpAutoWrap {...{ ...inpargs, idd: "status" }} />
          <div className="fr-sc">
            <Tag onClick={clear_filt} className="bod-red resp-text--2">
              clear filt
            </Tag>
          </div>
        </Card>

        <div className="fr-sc resp-gap-2 my-2">
          <InpText
            {...{
              id: "fqual-search",
              placeholder: "search",
              contprops: { className: "w-[20rem] bg-reg" },
              inpprops: { className: "w-full" },
              setter: (v) => {
                if (nils(v)) v = null;
                set_searchtxt(v);
              },
            }}
          />
          <span>filtered {viewfids.length}</span>
          <span>/</span>
          <span>{fids.length} Fquals</span>
          <div className="flex-1"></div>
          <Tag
            redirect="/fqual/new"
            className="bg-acc0/40 font-digi resp-text--1"
          >
            <span>New FQual</span>
          </Tag>
        </div>

        {qo_flist.isLoading ? (
          <Loader01c size="xs" />
        ) : qiserr(qo_flist) ? (
          <p className="text-center resp-text--1 text-red-400">
            {qiserr(qo_flist)}
          </p>
        ) : qissuccesss(qo_flist) && _.isEmpty(viewfids) ? (
          <p className="text-center resp-text--1 text-yellow-300">No Fquals</p>
        ) : qissuccesss(qo_flist) && !_.isEmpty(viewfids) ? (
          <div>
            {viewfids.map((fid, idx) => {
              let q = qost_fquals.qs[idx];
              if (qissuccesss(q)) {
                let f = getv(q, "data.result");
                let likeo = getv(f, "likeo") ?? {};

                return (
                  <Link target="_blank" to={`/fqual/${fid}`}>
                    <Card className={twMerge("bg-reg w-full resp-text--2")}>
                      <div className="fr-sc resp-gap-2">
                        <div class="fc-ss">
                          <div class="fr-sc resp-gap-2">
                            <span className="text-acc0">{fid}</span>
                            <span className="resp-text--1 font-digi">
                              {f.name}
                            </span>
                            <div class="flex-1"></div>

                            <div class="fr-sc resp-gap-2 font-digi cursor-pointer p-1">
                              <div
                                class={twMerge(
                                  "fr-sc resp-gap-1",
                                  likeo.like > likeo.dislike
                                    ? "text-green-400"
                                    : "text-white",
                                )}
                              >
                                <FontAwesomeIcon icon={faThumbsUp} />
                                <span>{getv(likeo, "like", 0)}</span>
                              </div>
                              <div
                                class={twMerge(
                                  "fr-sc resp-gap-1",
                                  likeo.like < likeo.dislike
                                    ? "text-red-400"
                                    : "text-white",
                                )}
                              >
                                <FontAwesomeIcon icon={faThumbsDown} />
                                <span>{getv(likeo, "dislike", 0)}</span>
                              </div>
                            </div>
                          </div>
                          <FincSection {...{ f }} />
                        </div>
                        <div className="flex-1"></div>
                        <div className="flex-1"></div>
                        <div class="fc-ss">
                          <span>{_.upperCase(f.status)}</span>
                          {f.status == "ended" && (
                            <span>
                              {iso_format(getv(f, "running_times.ed"))}
                            </span>
                          )}
                        </div>
                      </div>
                    </Card>
                  </Link>
                );
              } else
                return (
                  <Card className="fr-sc bg-transparent w-full resp-gap-2 rep-p-2 border border-acc0 rounded-md">
                    <span className="text-acc0">{fid}</span>
                    <div className="flex-1"></div>

                    <Loader01c size="s" />
                    <span>loading</span>
                  </Card>
                );
            })}
          </div>
        ) : null}

        <div className="h-[5rem]"></div>
      </div>
    </div>
  );
};

const FincSection = ({ f }) => {
  const appcon = useAppContext();
  const { tok_to_usd_val, tokmap } = appcon;
  const o = useMemo(() => {
    let o = _.cloneDeep(f.finc);
    if (nils(o)) return null;
    if (o.signup) {
      o.signup.amt_usd = tok_to_usd_val(o.signup.amt, o.signup.token);
    }
    if (o.races) {
      o.races = o.races.map((r) => {
        r.tot_fee_usd = tok_to_usd_val(r.tot_fee, r.token);
        r.tot_rfe_usd = tok_to_usd_val(r.tot_rfe, r.token);
        return r;
      });
      let usdrow = {
        token: "USD",
        tot_fee: _.sum(_.map(o.races, "tot_fee_usd")),
        tot_rfe: _.sum(_.map(o.races, "tot_rfe_usd")),
      };
      o.races = [...o.races, usdrow];
    }
    console.log("finc", f.fid, o);
    return o;
  }, [jstr(f), jstr(tokmap)]);
  return (
    <>
      {nils(o) ? null : (
        <table
          className={twMerge(
            tablecn.table,
            "w-max thintdrowp4-table resp-text--2",
          )}
        >
          <tbody>
            <tr className="">
              <td>SignUP</td>
              <td>Races[fee]</td>
              <td>Races[RFE]</td>
              <td>Prize[]</td>
            </tr>
            <tr>
              <td>
                <div class="fc-ss">
                  <div class="fr-ss resp-gap-1">
                    <span>
                      {dec(
                        getv(o, "signup.amt"),
                        tokdecn2(getv(o, "signup.token")),
                      ) ?? "--"}
                    </span>
                    <span>{getv(o, "signup.token") ?? "xx"}</span>
                  </div>

                  <div class="fr-ss resp-gap-1">
                    <span>{dec(getv(o, "signup.amt_usd"), "USD") ?? "--"}</span>
                    <span>USD</span>
                  </div>
                </div>
              </td>

              <td>
                <div class="fc-ss">
                  {getv(o, "races").map((r) => {
                    return (
                      <div class="fr-ss resp-gap-1">
                        <span>{dec(r?.tot_fee, tokdecn2(r.token))}</span>
                        <span>{r.token}</span>
                      </div>
                    );
                  })}
                </div>
              </td>

              <td>
                <div class="fc-ss">
                  {getv(o, "races").map((r) => {
                    return (
                      <div class="fr-ss resp-gap-1">
                        <span>{dec(r?.tot_rfe, tokdecn2(r.token))}</span>
                        <span>{r.token}</span>
                      </div>
                    );
                  })}
                </div>
              </td>

              <td>
                <div class="fr-ss resp-gap-1">
                  <span>{dec(getv(o, "prizes.prize_usd"), "USD") ?? "--"}</span>
                  <span>USD</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </>
  );
};

const F_DateInput = ({ path, type = "iso" }) => {
  let { nf, set_nf } = useFqualContext();

  return (
    <DateInput
      id={`fqualinfo-${path}`}
      defaultValue={getv(nf, path)}
      setter={() => {
        let val = extract_inp(`fqualinfo-${path}`);
        if (type == "iso") val = iso(val);
        set_state_ob(nf, set_nf, path, val);
      }}
    />
  );
};

const F_Input = ({
  path,
  type,
  disp_txt = null,
  el_postprocess_fn = (i) => i,
  ...props
}) => {
  let { nf, set_nf } = useFqualContext();
  return (
    <InpText
      {...props}
      id={`fqualinfo-${path}`}
      def_val={disp_txt ? disp_txt(getv(nf, path)) : getv(nf, path)}
      setter={() => {
        let val = extract_inp(`fqualinfo-${path}`);
        if (type == "n") {
          val = parseFloat(val);
          if (nils(val)) val = null;
        }
        if (el_postprocess_fn) val = el_postprocess_fn(val);
        set_state_ob(nf, set_nf, path, val);
      }}
    />
  );
};

const F_InputTextAr = ({
  path,
  type,
  el_postprocess_fn = (i) => i,
  delimiter = ",",
}) => {
  let { nf, set_nf } = useFqualContext();
  return (
    <InpText
      id={`fqualinfo-${path}`}
      def_val={(getv(nf, path) || []).join(`${delimiter} `)}
      setter={() => {
        let val = extract_inp(`fqualinfo-${path}`);
        val = _.chain(val)
          .split(delimiter)
          .map((e) => e.trim())
          .map((e) => {
            if (!nils(el_postprocess_fn)) e = el_postprocess_fn(e);
            return e;
          })
          .filter((e) => !nils(e))
          .value();
        set_state_ob(nf, set_nf, path, val);
      }}
    />
  );
};

const F_OptionInput = ({ path, type = null, options, txt_fn = (o) => o }) => {
  const fcon = useFqualContext();
  const { nf, set_nf } = fcon;
  const vals = getv(nf, path);

  return (
    <div className="fr-sc w-full flex-wrap resp-gap-1">
      {options.map((op) => {
        let active =
          type == "option"
            ? vals == op
            : type == "options" || type == "options-ar"
              ? (vals ?? []).includes(op)
              : false;
        return (
          <Tag
            key={op}
            onClick={() => {
              if (active) {
                if (type == "option") {
                  set_state_ob(nf, set_nf, path, null);
                } else if (type == "options") {
                  set_state_ob(
                    nf,
                    set_nf,
                    path,
                    (vals ?? []).filter((e) => e != op),
                  );
                } else if (type == "options-ar") {
                  set_state_ob(nf, set_nf, path, []);
                }
              } else {
                if (type == "option") {
                  set_state_ob(nf, set_nf, path, op);
                } else if (type == "options") {
                  set_state_ob(nf, set_nf, path, [...(vals || []), op]);
                } else if (type == "options-ar") {
                  set_state_ob(nf, set_nf, path, [op]);
                }
              }
            }}
            className={twMerge(
              "resp-text--2 font-mon transition duration-300",
              active ? "bg-acc0/50" : "bg-transparent text-white",
            )}
          >
            {txt_fn(op)}
          </Tag>
        );
      })}
    </div>
  );
};

const F_InputRange = ({
  path,
  type = "n",
  el_postprocess_fn = null,
  ...props
}) => {
  let { nf, set_nf } = useFqualContext();

  return (
    <div className="fr-sc">
      {["mi", "mx"].map((m) => {
        let id = `fqualinfo-${path}-${m}`;
        let valpath = `${path}.${m}`;
        return (
          <InpText
            contprops={{ className: "w-[4rem]" }}
            inpprops={{ className: "w-full" }}
            placeholder={m}
            id={id}
            def_val={getv(nf, valpath)}
            setter={() => {
              let val = extract_inp(id);
              if (type == "iso") val = iso(val);
              if (type == "n") {
                val = parseFloat(val);
                if (nils(val)) val = null;
              }
              if (el_postprocess_fn) val = el_postprocess_fn(val);
              set_state_ob(nf, set_nf, valpath, val);
            }}
            {...props}
          />
        );
      })}
    </div>
  );
};

export const InfoTab = () => {
  const fcon = useFqualContext();
  const { nf, set_nf } = fcon;

  return (
    <>
      <table
        className={twMerge("thintdrowp4-table mx-auto w-max resp-text--2")}
      >
        <tbody>
          <tr>
            <td>name</td>
            <td colSpan={12}>
              <F_Input
                {...{
                  path: "name",
                  type: "txt",
                  contprops: { className: "w-full" },
                  inpprops: { className: "w-full" },
                }}
              />
            </td>
          </tr>

          <tr className="">
            <td>Qualifying Times</td>
            <td colSpan={6}>
              <F_DateInput {...{ path: "qualifying_times.st" }} />
            </td>
            <td>
              <F_DateInput {...{ path: "qualifying_times.ed" }} />
            </td>
          </tr>

          <tr className="">
            <td>Running Times</td>
            <td colSpan={6}>
              <F_DateInput {...{ path: "running_times.st" }} />
            </td>
            <td>
              <F_DateInput {...{ path: "running_times.ed" }} />
            </td>
          </tr>

          <tr>
            <td>status</td>
            <td colSpan={12}>
              <F_OptionInput
                {...{
                  path: "status",
                  type: "option",
                  options: [
                    "yettostart",
                    "qualifying",
                    "scheduled",
                    "running",
                    "ending",
                    "ended",
                    "cancelled",
                  ],
                }}
              />
            </td>
          </tr>

          <tr>
            <td>Hide</td>
            <td colSpan={12}>
              <F_OptionInput
                {...{
                  path: "hide",
                  type: "option",
                  options: [true, false],
                  txt_fn: (o) => (o ? "HIDE" : "Dont Hide"),
                }}
              />
            </td>
          </tr>

          <tr>
            <td>mainmenu_hide</td>
            <td colSpan={12}>
              <F_OptionInput
                {...{
                  path: "mainmenu_hide",
                  type: "option",
                  options: [true, false],
                  txt_fn: (o) => (o ? "HIDE" : "Dont Hide"),
                }}
              />
            </td>
          </tr>

          <tr>
            <td>entry_user_resettable</td>
            <td colSpan={2}>
              <F_OptionInput
                {...{
                  path: "entry_user_resettable",
                  type: "option",
                  options: [true, false],
                  txt_fn: (o) => (o ? "YES" : "NO"),
                }}
              />
            </td>
            <td colSpan={4}>
              <div class="fr-sc">
                <F_Input
                  {...{
                    path: "entry_user_reset_minlim",
                    postprocess_fn: (e) => (nils(e) ? "" : parseInt(e)),
                  }}
                />
              </div>
            </td>
          </tr>

          <tr>
            <td>Scoring</td>
          </tr>
          <tr>
            <td>Sorting</td>
            <td colSpan={12}>
              <F_OptionInput
                {...{
                  path: "scoring.sorting",
                  type: "option",
                  options: [
                    "all",
                    "first_raced",
                    "last_raced",
                    "fastest_time",
                    "slowest_time",
                    "highest_eth",
                    "best_score",
                  ],
                }}
              />
            </td>
          </tr>

          <tr>
            <td>use skins</td>
            <td colSpan={12}>
              <F_OptionInput
                {...{
                  path: "use_skinteams",
                  type: "option",
                  options: [true, false, null],
                  txt_fn: (o) => (nils(o) ? "null" : o ? "true" : "false"),
                }}
              />
            </td>
          </tr>

          <tr>
            <td>skin required</td>
            <td colSpan={12}>
              <F_OptionInput
                {...{
                  path: "skin_required",
                  type: "option",
                  options: [true, false, null],
                  txt_fn: (o) => (nils(o) ? "null" : o ? "true" : "false"),
                }}
              />
            </td>
          </tr>

          <tr>
            <td>use agents</td>
            <td colSpan={12}>
              <F_OptionInput
                {...{
                  path: "use_agents",
                  type: "option",
                  options: [true, false, null],
                  txt_fn: (o) => (nils(o) ? "null" : o ? "true" : "false"),
                }}
              />
            </td>
          </tr>

          <tr>
            <td>agent required</td>
            <td colSpan={12}>
              <F_OptionInput
                {...{
                  path: "agent_required",
                  type: "option",
                  options: [true, false, null],
                  txt_fn: (o) => (nils(o) ? "null" : o ? "true" : "false"),
                }}
              />
            </td>
          </tr>

          <tr>
            <td>Format</td>
            <td colSpan={12}>
              <F_OptionInput
                {...{
                  path: "scoring.format",
                  type: "option",
                  options: ["total", "average"],
                }}
              />
            </td>
          </tr>
          <tr>
            <td>Selecting</td>
            <td colSpan={12}>
              <F_Input
                {...{
                  path: "scoring.selecting",
                  type: "txt",
                }}
              />
            </td>
          </tr>

          <tr>
            <td>Score.By</td>
            <td colSpan={12}>
              <F_OptionInput
                {...{
                  path: "scoring.scoring_by",
                  type: "option",
                  options: [
                    "pos",
                    "prize_eth",
                    "prize_usd",
                    "time",
                    "hightime",
                    "consec_wins",
                    "relay",
                    "mission_points",
                    "adv_points",
                    "jackpot_wins",
                    "quest_cards",
                  ],
                }}
              />
            </td>
          </tr>

          <tr>
            <td>Score.By-Jackpot-Wins</td>
            <td colSpan={12}>
              <F_OptionInput
                {...{
                  path: "scoring.jtype",
                  type: "options",
                  options: ["minorpot", "majorpot", "maxpot"],
                }}
              />
            </td>
          </tr>
          <tr>
            <td>Score.By-Realy-CBs</td>
            <td colSpan={12}>
              <F_OptionInput
                {...{
                  path: "scoring.relay_cbs",
                  type: "options",
                  options: [
                    ...[9, 11, 13, 19, 21, 23],
                    ...[10, 12, 14, 16, 18, 20, 22],
                    ...[15, 17],
                  ],
                }}
              />
            </td>
          </tr>

          <tr>
            <td>for_faction_game</td>
            <td colSpan={12}>
              <F_OptionInput
                {...{
                  path: "for_faction_game",
                  type: "option",
                  options: ["race", "agent", "faction"],
                }}
              />
            </td>
          </tr>

          <tr>
            <td>RV Mode</td>
            <td colSpan={12}>
              <F_OptionInput
                {...{
                  path: "rvmode",
                  type: "options",
                  options: ["car", "bike", "horse"],
                }}
              />
            </td>
          </tr>

          <tr>
            <td>Leader Mode</td>
            <td colSpan={12}>
              <F_OptionInput
                {...{
                  path: "leader_mode",
                  type: "option",
                  options: ["bike", "vault", "faction", "faction_agent"],
                }}
              />
            </td>
          </tr>

          <tr>
            <td>
              <div class="fc-ss text-acc0">
                <span>LeaderMode == faction_agent</span>
                <span>Filters</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>Factions selected</td>
            <td colSpan={12}>
              <F_OptionInput
                {...{
                  type: "options",
                  path: "factionagfilt.factions",
                  options: [
                    "ordinem",
                    "project-paragon",
                    "root-prime",
                    "the-divine-wind",
                  ],
                }}
              />
            </td>
          </tr>
          <tr>
            <td>Qualifying Score</td>
            <td colSpan={12}>
              <F_Input
                {...{
                  path: "qualifying_score",
                  el_postprocess_fn: (e) => parseInt(e),
                  contprops: { className: "w-[4rem]" },
                }}
              />
            </td>
          </tr>

          <tr>
            <td>TOP [type]</td>
            <td colSpan={12}>
              <F_OptionInput
                {...{
                  path: "qualifiers_n_mode",
                  type: "option",
                  options: ["n", "%age"],
                }}
              />
            </td>
          </tr>

          <tr>
            <td>TOP [val]</td>
            <td colSpan={12}>
              <F_Input
                {...{
                  path: "qualifiers_n",
                  el_postprocess_fn: (e) => parseInt(e),
                  contprops: { className: "w-[4rem]" },
                }}
              />
            </td>
          </tr>

          <tr>
            <td>
              <div class="fc-ss">
                <span>Trainer Highlights</span>
                <span>format: regular-trainer</span>
                <span>eg: 12-12</span>
              </div>
            </td>
            <td colSpan={12}>
              <F_Input
                {...{
                  path: "trainer_highlights",
                  // el_postprocess_fn: (e) => parseInt(e),
                  contprops: { className: "w-[7rem]" },
                }}
              />
            </td>
          </tr>

          <tr>
            <td>#RacesN[mi.race limit]</td>
            <td colSpan={12}>
              <F_Input
                {...{
                  path: "qualifying_ranklim",
                  el_postprocess_fn: (e) => parseInt(e),
                  contprops: { className: "w-[4rem]" },
                }}
              />
            </td>
          </tr>

          <tr>
            <td>Win[mi.win limit]</td>
            <td colSpan={12}>
              <F_Input
                {...{
                  path: "qualifying_winlim",
                  el_postprocess_fn: (e) => parseInt(e),
                  contprops: { className: "w-[4rem]" },
                }}
              />
            </td>
          </tr>

          <tr>
            <td>Tie Breaker</td>
            <td colSpan={12}>
              <F_OptionInput
                {...{
                  path: "scoring.tiebreaker",
                  type: "option",
                  options: ["less-races-better", "more-races-better"],
                }}
              />
            </td>
          </tr>

          <tr>
            <td>is F-Type split?</td>
            <td colSpan={12}>
              <F_OptionInput
                {...{
                  path: "ftype_split",
                  type: "option",
                  options: [true, false],
                  txt_fn: (o) => (o ? "true" : "false"),
                }}
              />
            </td>
          </tr>

          <tr>
            <td>
              <span className="text-acc0">Race Criteria</span>
              <span className="text-white">
                [{getv(nf, "scoring.criteria")?.length || 0} Rules]
              </span>
            </td>
            <td>
              <Tag
                onClick={() => {
                  let new_criterias = [
                    ...(getv(nf, "scoring.criteria") || []),
                    { race: {}, posscores: [...Array(16)].fill(0) },
                  ];
                  set_state_ob(nf, set_nf, "scoring.criteria", new_criterias);
                }}
                className="fc-cc p-2 rounded-full bg-green-500 w-max mx-auto"
              >
                <FontAwesomeIcon icon={faPlus} />
              </Tag>
            </td>
          </tr>
          {(getv(nf, "scoring.criteria") || []).map((e, i) => {
            return (
              <tr colSpan={13} key={i}>
                <td colSpan={13}>
                  <Card className={"w-full"}>
                    <div className="fr-sc resp-gap-2 rounded-lg p-2 bg-dark w-max mr-auto">
                      <Tag>#{i + 1}</Tag>
                      <div className="flex-1"></div>
                      <Tag
                        onClick={() => {
                          let new_criterias = _.chain(
                            getv(nf, "scoring.criteria"),
                          )
                            .filter((e, idx) => idx != i)
                            .value();

                          set_state_ob(
                            nf,
                            set_nf,
                            "scoring.criteria",
                            new_criterias,
                          );
                        }}
                        className="fc-cc p-2 rounded-full bg-red-500 w-max mx-auto"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Tag>
                    </div>

                    <table className="w-full thintdrowp4-table">
                      <tbody>
                        <tr>
                          <td>version</td>
                          <td>
                            <F_OptionInput
                              path={`scoring.criteria.${i}.race.version`}
                              type="options"
                              options={[1, 2, 3]}
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>rvmode</td>
                          <td>
                            <F_OptionInput
                              path={`scoring.criteria.${i}.race.rvmode`}
                              type="options"
                              options={["car", "bike", "horse"]}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>paytoken</td>
                          <td>
                            <F_OptionInput
                              path={`scoring.criteria.${i}.race.paytoken`}
                              type="options"
                              options={["WETH", "DEZ"]}
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>class</td>
                          <td>
                            <F_OptionInput
                              path={`scoring.criteria.${i}.race.class`}
                              type="options"
                              options={[40, 50, 60, 70, 80, 90, 5]}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>CB</td>
                          <td>
                            <F_OptionInput
                              path={`scoring.criteria.${i}.race.cb`}
                              type="options"
                              options={[
                                ...[9, 11, 13, 19, 21, 23],
                                ...[10, 12, 14, 16, 18, 20, 22],
                                ...[15, 17],
                              ]}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>format</td>
                          <td>
                            <F_OptionInput
                              path={`scoring.criteria.${i}.race.format`}
                              type="options"
                              options={[
                                "normal",
                                "reduced",
                                "spin_n_go",
                                "sub_rounds",
                                "sub_roundsp",
                                "sub_bounty",
                                "sub_quest",
                              ]}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>R-Gates</td>
                          <td>
                            <div class="fc-ss">
                              <F_InputTextAr
                                delimiter=","
                                path={`scoring.criteria.${i}.race.rgate`}
                                type="options"
                                el_postprocess_fn={(e) => parseInt(e)}
                              />

                              <p className="resp-text--3">
                                {jstr(
                                  getv(nf, `scoring.criteria.${i}.race.rgate`),
                                )}
                              </p>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>Payout</td>
                          <td>
                            <div className="fc-ss">
                              <F_OptionInput
                                path={`scoring.criteria.${i}.race.payout`}
                                type="options"
                                options={[
                                  "wta",
                                  "top2",
                                  "top3",
                                  "top4",
                                  "dblup",
                                  "pity",
                                ]}
                              />
                              <F_InputTextAr
                                path={`scoring.criteria.${i}.race.payout`}
                              />
                              <p className="resp-text--3">
                                {jstr(
                                  getv(nf, `scoring.criteria.${i}.race.payout`),
                                )}
                              </p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Race Name</td>
                          <td colSpan={12}>
                            <div className="fc-ss">
                              <F_InputTextAr
                                path={`scoring.criteria.${i}.race.race_name`}
                              />
                              <p className="resp-text--3">
                                {jstr(
                                  getv(
                                    nf,
                                    `scoring.criteria.${i}.race.race_name`,
                                  ),
                                )}
                              </p>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>is_chain</td>
                          <td>
                            <F_OptionInput
                              path={`scoring.criteria.${i}.race.is_chain`}
                              type="option"
                              options={[true, false, null]}
                              txt_fn={(o) =>
                                nils(o) ? "null" : o === true ? "true" : "false"
                              }
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>fee</td>
                          <td>
                            <div class="fr-sc">
                              <F_InputRange
                                path={`scoring.criteria.${i}.race.fee`}
                              />

                              <F_OptionInput
                                path={`scoring.criteria.${i}.race.paytoken`}
                                type="options"
                                options={["WETH", "DEZ"]}
                              />
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>FEE_USD[init_feeusd]</td>
                          <td>
                            <div class="fr-sc">
                              <F_InputRange
                                path={`scoring.criteria.${i}.race.init_feeusd`}
                              />
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>skin_rvmodes</td>
                          <td colSpan={12}>
                            <div className="fc-ss">
                              <F_OptionInput
                                path={`scoring.criteria.${i}.race.skin_rvmodes`}
                                type="options"
                                options={["car", "bike", "horse"]}
                              />
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>skin</td>
                          <td colSpan={12}>
                            <div className="fc-ss">
                              <F_Input
                                path={`scoring.criteria.${i}.race.skin`}
                                disp_txt={(v) => {
                                  if (v?.by == "any") return "any";
                                  if (_.isObject(v)) {
                                    return `${v.by}=${(v.ar ?? []).join(",")}`;
                                  }
                                  return "";
                                }}
                                el_postprocess_fn={(e) => {
                                  if (nils(e)) return null;
                                  else if (e == "any") return { by: "any" };
                                  else if (e.includes("=")) {
                                    let by = e.split("=")[0];
                                    let ar = e.split("=")[1].split(",");
                                    return { by, ar };
                                  }
                                  return null;
                                }}
                              />

                              <p className="resp-text--3">
                                {jstr(
                                  getv(nf, `scoring.criteria.${i}.race.skin`),
                                )}
                              </p>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>eventtags</td>
                          <td>
                            <div class="fc-ss my-2 resp-gap-2">
                              <F_OptionInput
                                path={`scoring.criteria.${i}.race.eventtags`}
                                type="options"
                                options={[
                                  // "maiden_qual",
                                  "F1-2",
                                  "Genesis",
                                  "ME",
                                  "Maiden Qualifer",
                                  "CLM<0.01",
                                  "CLM<0.02",
                                  "CLM<0.03",
                                  "CLM<0.04",
                                  "CLM<0.05",
                                  "CLM<0.10",
                                  "CLM<0.20",
                                  "Trainer",
                                  "Quest_Finale",
                                ]}
                                txt_fn={(o) => o}
                              />
                              <F_InputTextAr
                                path={`scoring.criteria.${i}.race.eventtags`}
                              />
                              <p className="resp-text--3">
                                {jstr(
                                  getv(
                                    nf,
                                    `scoring.criteria.${i}.race.eventtags`,
                                  ),
                                )}
                              </p>{" "}
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>NOT eventtags</td>
                          <td>
                            <div class="fc-ss my-2 resp-gap-2">
                              <F_OptionInput
                                path={`scoring.criteria.${i}.race.not_eventtags`}
                                type="options"
                                options={[
                                  // "maiden_qual",
                                  "F1-2",
                                  "Genesis",
                                  "ME",
                                  "Maiden Qualifer",
                                  "CLM<0.01",
                                  "CLM<0.02",
                                  "CLM<0.03",
                                  "CLM<0.04",
                                  "CLM<0.05",
                                  "CLM<0.10",
                                  "CLM<0.20",
                                  "Trainer",
                                  "Quest_Finale",
                                ]}
                                txt_fn={(o) => o}
                              />
                              <F_InputTextAr
                                path={`scoring.criteria.${i}.race.not_eventtags`}
                              />
                              <p className="resp-text--3">
                                {jstr(
                                  getv(
                                    nf,
                                    `scoring.criteria.${i}.race.not_eventtags`,
                                  ),
                                )}
                              </p>{" "}
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>Dynamic Scoring</td>
                          <td colSpan={12}>
                            <F_Input
                              {...{
                                path: `scoring.criteria.${i}.dynamic_scoring`,
                                postprocess_fn: (e) => parseInt(e),
                              }}
                            />
                          </td>
                        </tr>
                        {parseInt(
                          getv(nf, `scoring.criteria.${i}.dynamic_scoring`),
                        ) > 0 ? (
                          <>
                            {[
                              ...new Array(
                                parseInt(
                                  getv(
                                    nf,
                                    `scoring.criteria.${i}.dynamic_scoring`,
                                  ),
                                ),
                              ),
                            ].map((e, idx) => {
                              return (
                                <tr key={idx}>
                                  <td>#{idx + 1}-PosScores</td>
                                  <td colSpan={12}>
                                    <table className="thintdrowp4-table">
                                      <tbody>
                                        <tr className="">
                                          {[...Array(15)]
                                            .map((e, i) => pos_txt(i + 1))
                                            .map((p) => (
                                              <td>{p}</td>
                                            ))}
                                        </tr>
                                        <tr>
                                          {[...Array(15)].map((e, pidx) => (
                                            <td key={pidx}>
                                              <F_Input
                                                path={`scoring.criteria.${i}.dy_posscores.${idx}.${pidx}`}
                                                type="n"
                                                contprops={{
                                                  className: "w-[3rem]",
                                                }}
                                              />
                                            </td>
                                          ))}
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        ) : (
                          <tr>
                            <td>PosScores</td>
                            <td colSpan={12}>
                              <table className="thintdrowp4-table">
                                <tbody>
                                  <tr className="">
                                    {[...Array(15)]
                                      .map((e, i) => pos_txt(i + 1))
                                      .map((p) => (
                                        <td>{p}</td>
                                      ))}
                                    {/*<td>#BikeCriteria</td> */}
                                  </tr>
                                  <tr>
                                    {[...Array(15)].map((e, pidx) => (
                                      <td>
                                        <F_Input
                                          path={`scoring.criteria.${i}.posscores.${pidx}`}
                                          type="n"
                                          contprops={{ className: "w-[3rem]" }}
                                        />
                                      </td>
                                    ))}
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </Card>
                </td>
              </tr>
            );
          })}

          <tr className="">
            <td>
              <span className="text-acc0">Bike Criteria</span>
              <span className="text-white">
                [{getv(nf, "entry_filters")?.length || 0} Rules]
              </span>
            </td>
            <td>
              <Tag
                onClick={() => {
                  let new_criterias = [
                    ...(getv(nf, "entry_filters") || []),
                    {},
                  ];
                  set_state_ob(nf, set_nf, "entry_filters", new_criterias);
                }}
                className="fc-cc p-2 rounded-full bg-green-500 w-max mx-auto"
              >
                <FontAwesomeIcon icon={faPlus} />
              </Tag>
            </td>
          </tr>
          {(getv(nf, "entry_filters") || []).map((e, i) => {
            return (
              <tr colSpan={13} key={i}>
                <td colSpan={13}>
                  <Card className={"w-full"}>
                    <div className="fr-sc resp-gap-2 rounded-lg p-2 bg-dark w-max mr-auto">
                      <Tag>#{i + 1}</Tag>
                      <div className="flex-1"></div>
                      <Tag
                        onClick={() => {
                          let new_criterias = _.chain(getv(nf, "entry_filters"))
                            .filter((e, idx) => idx != i)
                            .value();

                          set_state_ob(
                            nf,
                            set_nf,
                            "entry_filters",
                            new_criterias,
                          );
                        }}
                        className="fc-cc p-2 rounded-full bg-red-500 w-max mx-auto"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Tag>
                    </div>
                    <table className="w-full thintdrowp4-table">
                      <tbody>
                        <tr>
                          <td>element</td>
                          <td>
                            <F_OptionInput
                              path={`entry_filters.${i}.element`}
                              type="options"
                              options={["metal", "fire", "earth", "water"]}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>type</td>
                          <td>
                            <F_OptionInput
                              path={`entry_filters.${i}.type`}
                              type="options"
                              options={[
                                "genesis",
                                "morphed",
                                "freak",
                                "xclass",
                                "trainer",
                              ]}
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>trainer_type</td>
                          <td>
                            <F_OptionInput
                              path={`entry_filters.${i}.trainer_type`}
                              type="options"
                              options={["pure", "facgame"]}
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>Gender</td>
                          <td>
                            <F_OptionInput
                              path={`entry_filters.${i}.gender`}
                              type="options"
                              options={["male", "female"]}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>F.No.</td>
                          <td>
                            <div className="fc-ss">
                              <F_Input
                                path={`entry_filters.${i}.fno`}
                                disp_txt={(v) => {
                                  if (nils(v)) return "";
                                  else if (_.isArray(v)) return v.join(",");
                                  else if (_.isObject(v))
                                    return `${v.mi}-${v.mx}`;
                                  else return v;
                                }}
                                el_postprocess_fn={(e) => {
                                  if (nils(e)) return null;
                                  else if (e.includes(","))
                                    return e.split(",").map((e) => parseInt(e));
                                  else if (e.includes("-"))
                                    return {
                                      mi: parseInt(e.split("-")[0]),
                                      mx: parseInt(e.split("-")[1]),
                                    };
                                  else return [parseInt(e)];
                                }}
                              />
                              <p className="resp-text--3">
                                {jstr(getv(nf, `entry_filters.${i}.fno`))}
                              </p>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>skin_rvmodes</td>
                          <td colSpan={12}>
                            <div className="fc-ss">
                              <F_OptionInput
                                path={`entry_filters.${i}.skin_rvmodes`}
                                type="options"
                                options={["car", "bike", "horse"]}
                              />
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>skin[only signup]</td>
                          <td colSpan={12}>
                            <div className="fc-ss">
                              <F_Input
                                path={`entry_filters.${i}.skin`}
                                disp_txt={(v) => {
                                  if (v?.by == "any") return "any";
                                  if (_.isObject(v)) {
                                    return `${v.by}=${(v.ar ?? []).join(",")}`;
                                  }
                                  return "";
                                }}
                                el_postprocess_fn={(e) => {
                                  if (nils(e)) return null;
                                  else if (e == "any") return { by: "any" };
                                  else if (e.includes("=")) {
                                    let by = e.split("=")[0];
                                    let ar = e.split("=")[1].split(",");
                                    return { by, ar };
                                  }
                                  return null;
                                }}
                              />

                              <p className="resp-text--3">
                                {jstr(getv(nf, `entry_filters.${i}.skin`))}
                              </p>
                            </div>
                          </td>
                        </tr>
                        {["races_n", "win_p", "paid_races_n", "paid_win_p"].map(
                          (k) => {
                            return (
                              <React.Fragment key={k}>
                                <tr>
                                  <td>{k} [only signup]</td>
                                  <td>
                                    <div className="fr-sc">
                                      <F_Input
                                        path={`entry_filters.${i}.${k}.cb`}
                                        placeholder="cb/all"
                                        contprops={{
                                          className: "w-[4rem]",
                                        }}
                                      />
                                      <F_InputRange
                                        path={`entry_filters.${i}.${k}`}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          },
                        )}
                        <tr>
                          <td>Birthday</td>

                          <td>
                            <div className="fr-sc">
                              <F_DateInput
                                {...{ path: `entry_filters.${i}.birthday` }}
                              />
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>is_maiden</td>
                          <td>
                            <div className="fr-sc">
                              <F_OptionInput
                                path={`entry_filters.${i}.is_maiden`}
                                type={"option"}
                                options={[true, false]}
                                txt_fn={(o) => (o ? "true" : "false")}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Card>
                </td>
              </tr>
            );
          })}

          <tr>
            <td>
              <span className="text-acc0">Title Rules</span>
              <span className="text-white">
                [{getv(nf, "title_rules")?.length || 0} Rules]
              </span>
            </td>
            <td>
              <Tag
                onClick={() => {
                  let new_trules = [
                    ...(getv(nf, "title_rules") || []),
                    ["", ""],
                  ];
                  set_state_ob(nf, set_nf, "title_rules", new_trules);
                }}
                className="fc-cc p-2 rounded-full bg-green-500 w-max mx-auto"
              >
                <FontAwesomeIcon icon={faPlus} />
              </Tag>
            </td>
          </tr>
          {(getv(nf, "title_rules") || []).map((e, i) => {
            return (
              <tr key={`title_rules-${i}`}>
                <td>
                  <div className="fr-sc resp-gap-2 rounded-lg p-2 bg-dark w-max mr-auto">
                    <Tag>#{i + 1}</Tag>
                    <div className="flex-1"></div>
                    <Tag
                      onClick={() => {
                        let new_trules = _.chain(getv(nf, "title_rules"))
                          .filter((e, idx) => idx != i)
                          .value();

                        set_state_ob(nf, set_nf, "title_rules", new_trules);
                      }}
                      className="fc-cc p-2 rounded-full bg-red-500 w-max mx-auto"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Tag>
                  </div>
                </td>
                <td colSpan={3}>
                  <div className="w-full">
                    <F_Input
                      path={`title_rules.${i}.0`}
                      contprops={{ className: "w-[8rem]" }}
                      inpprops={{ className: "w-[8rem]" }}
                    />
                  </div>
                </td>
                <td colSpan={9}>
                  <div className="w-full">
                    <F_Input
                      path={`title_rules.${i}.1`}
                      contprops={{ className: "w-full" }}
                      inpprops={{ className: "w-full" }}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

const EntrantsTable = ({ fid, fe, qe }) => {
  const rem_entrant = async (hid) => {
    try {
      let resp = await q_admin_fqual_delete_entrant({
        fid,
        hids: [hid],
      }).queryFn();
      setTimeout(qe.refresh, 2000);
    } catch (err) {
      console.log(err);
    }
  };

  const copy_entrants = () => {
    try {
      let ar = _.chain(fe.entrants)
        .map((e) => {
          let { hid, vault } = e;
          let v = fe.vmap[vault];
          let h = fe.hmap[hid];
          let t = fe.txnsmap[hid];
          return {
            hid,
            name: h.name,
            details: `${h.type} F${h.fno} ${h.element} ${h.gender}`,
            vault: vault,
            vault_name: v,
            entered_at: h.entered_at ?? "-",
            status:
              h.admin_entry == true
                ? "Admin Entry"
                : !nils(t?.id)
                  ? "Confirmed"
                  : "Awaiting...",
          };
        })
        .value();
      let keys = "hid,name,details,vault,vault_name,enterd_at,status".split(
        ",",
      );
      copy_to_sheet_table(ar, keys);
    } catch (err) {}
  };

  return (
    <div>
      <div class="fr-sc resp-gap-1">
        <Tag
          onClick={() => {
            copy_entrants();
          }}
          className={twMerge("bg-acc0/40")}
        >
          {" "}
          Copy Entrants{" "}
        </Tag>
      </div>
      <table className={twMerge(tablecn.table, "w-max")}>
        <thead>
          <tr>
            <td></td>
            <td>Bike</td>
            <td>Vault</td>
            <td>Entry</td>
            <td>Status</td>
          </tr>
        </thead>
        <tbody>
          {(fe?.entrants || []).map((e) => {
            let { hid, vault } = e;
            let v = fe.vmap[vault];
            let h = fe.hmap[hid];
            let t = fe.txnsmap[hid];
            return (
              <tr className="thintdrow">
                <td>
                  {!nils(t) && (
                    <Link target="_blank" to={polytxnidlink(t.id)}>
                      <div className="w-[2rem]">
                        <Img img={polychainimg} />
                      </div>
                    </Link>
                  )}
                </td>
                <td>
                  <Link to={`/bike/${hid}`}>
                    <div className="fc-ss">
                      <div className="fr-sc w-full resp-gap-1">
                        <span className="text-acc0 resp-text--1">{h.hid}</span>
                        <span>-</span>
                        <span className="font-digi resp-text-1">{h.name}</span>
                      </div>
                      <div className="fr-sc resp-text--2 text-acc0 resp-gap-2">
                        <span>{_.capitalize(h.type)}</span>
                        <span>F{h.fno}</span>
                        <span>{h.element}</span>
                        <span>{h.gender}</span>
                      </div>
                    </div>
                  </Link>
                </td>
                <td>
                  <Link to={`/vault/${vault}`}>
                    <div className="fc-ss">
                      <>
                        <span className="font-digi resp-text-1">{v}</span>
                        <span className="text-acc0 resp-text--1">{vault}</span>
                      </>
                    </div>
                  </Link>
                </td>
                <td>
                  <span>{iso_format(e.entered_at)}</span>
                </td>
                <td>
                  {e.admin_entry == true ? (
                    <div class="fr-sc">
                      <span className="text-yellow-400">Admin Entry</span>
                      <span
                        onClick={() => rem_entrant(hid)}
                        className="p-1 cursor-pointer text-red-300 resp-text-1"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </span>
                    </div>
                  ) : !nils(t?.id) ? (
                    <span className="text-green-400">Confirmed</span>
                  ) : (
                    <span className="text-orange-400">Awaiting...</span>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export const SignupTab = () => {
  const fcon = useFqualContext();
  const { nf, set_nf, fid } = fcon;

  const [qe] = useQueries([
    q_admin_fqual_entrants(
      { fid: fid },
      { enabled: !nils(fid) && fid !== "new" },
    ),
  ]);
  const fe = useMemo(() => getv(qe, "data.result") || []);

  const fe_refresh = async () => {
    await qe.refetch();
  };

  const add_bulk_enrants = async () => {
    try {
      let vals = extract_inp("inp_bulk_entrants");
      let hids = vals
        .split("\n")
        .map((e) => e.trim())
        .map((e) => parseInt(e));

      let resp = await q_admin_fqual_add_entrant({ fid, hids }).queryFn();
      set_inp("inp_bulk_entrants", "");
      setTimeout(fe_refresh, 2000);
    } catch (err) {
      console.log(err);
    }
  };

  const del_bulk_enrants = async () => {
    try {
      let vals = extract_inp("inp_bulk_entrants");
      let hids = vals
        .split("\n")
        .map((e) => e.trim())
        .map((e) => parseInt(e));

      let resp = await q_admin_fqual_delete_entrant({ fid, hids }).queryFn();
      set_inp("inp_bulk_entrants", "");
      setTimeout(fe_refresh, 2000);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <div className="fc-cc resp-text--1 resp-gap-2 my-2">
        <span>
          Signup Created: {getv(nf, "signup_created") ? "true" : "false"}
        </span>
        {getv(nf, "signup_created") == true && (
          <>
            <Link to={`https://fbike.dnaracing.run/race/${nf.signup_rid}`}>
              <Tag className="bg-acc0/40">
                Signup Rid: {getv(nf, "signup_rid")}
              </Tag>
            </Link>
            <span>
              Signup Entry done: {getv(nf, "signup_entry") ? "true" : "false"}
            </span>
          </>
        )}
      </div>

      <table
        className={twMerge("thintdrowp4-table mx-auto w-max resp-text--2")}
      >
        <tbody>
          <tr>
            <td>Auto Signup Race</td>
            <td colSpan={5}>
              <F_OptionInput
                path={`signup.auto_signup_race`}
                type="option"
                options={[true, false]}
                txt_fn={(o) => (o ? "true" : "false")}
              />
            </td>
          </tr>

          <tr>
            <td>fee_usd</td>
            <td colSpan={5}>
              <F_Input path={`signup.fee_usd`} type={"n"} />
            </td>
          </tr>

          <tr>
            <td>entrants_n</td>
            <td colSpan={5}>
              <F_Input path={`signup.rgate`} type={"n"} />
            </td>
          </tr>

          <tr>
            <td>race version</td>
            <td colSpan={5}>
              <F_OptionInput
                path={`signup.version`}
                type={"option"}
                options={[2, 3, 5]}
                txt_fn={(o) =>
                  o == 2
                    ? "v2-WETH"
                    : o == 3
                      ? "v3-DEZ"
                      : o == 5
                        ? "v5-TP"
                        : "--"
                }
              />
            </td>
          </tr>

          <tr>
            <td>is_blind</td>
            <td colSpan={5}>
              <F_OptionInput
                path={`signup.is_blind`}
                type={"option"}
                options={[true, false]}
                txt_fn={(o) => (o === true ? "Blind" : "no")}
              />
            </td>
          </tr>

          <tr>
            <td>Unique Entrants across</td>
            <td>
              <div className="fc-ss">
                <F_InputTextAr path={`signup.uniq_entrants_acc_fids`} />
                <p className="resp-text--3">
                  {jstr(getv(nf, `signup.uniq_entrants_acc_fids`))}
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="h-[2rem]"></div>

      <div class="w-[30rem] mx-auto">
        <textarea
          id="inp_bulk_entrants"
          className="w-full p-2 outline-none border border-acc0 rounded-md bg-black h-[10rem]"
        ></textarea>
        <div class="fr-sc">
          <div class="flex-1"></div>

          <Tag onClick={del_bulk_enrants} className="bg-red-400/40 font-digi ">
            <span className="resp-text--2">Delete Entrants</span>
          </Tag>
          <Tag onClick={add_bulk_enrants} className="bg-acc0/40 font-digi ">
            <span className="resp-text--2">Add Entrants</span>
          </Tag>
        </div>
      </div>
      <div class="fr-sc resp-gap-2">
        <span>Excluded</span>
        <span>[ {fe.exclude_hids?.length || 0} ]</span>
        <span> {(fe?.exclude_hids || []).join(", ")} </span>
      </div>
      <div className="h-[2rem]"></div>
      {qe.isLoading ? (
        <Loader01c size="s" />
      ) : qiserr(qe) ? (
        <p className="text-center resp-text--1 text-red-400">{qiserr(qe)}</p>
      ) : qissuccesss(qe) ? (
        <div>
          <div className="fr-cc resp-gap-2">
            <p className="resp-text-1 font-digi text-center my-2">Entrants</p>
            <Tag onClick={fe_refresh} className="text-orange-500">
              <FontAwesomeIcon icon={faSync} />
            </Tag>
          </div>
          <EntrantsTable {...{ fid, fe, qe }} />
        </div>
      ) : null}
    </div>
  );
};

export const DeleteFinale = () => {
  let fcon = useFqualContext();
  const { qo_doc, nf, set_nf } = fcon;
  const [resp, set_resp] = useState(null);
  const del = async () => {
    try {
      set_resp({ loading: true });
      let resp = await q_admin_fqual_delete_finale({ fid: nf.fid }).queryFn();
      let done = getv(resp, "result.done");
      if (done == 1) {
        set_resp({ type: "msg", msg: "Finale Deleted" });
        setTimeout(qo_doc.refetch, 5000);
      }
      if (getv(resp, "err")) {
        throw new Error(getv(resp, "err"));
      }
    } catch (err) {
      let err_msg = err.message;
      set_resp({ type: "err", msg: err_msg });
    }
  };
  return (
    <div className="fr-sc">
      <Tag onClick={del} className="bg-red-400/40 font-digi resp-text--2">
        delete Finale
      </Tag>
      {resp && (
        <>
          {resp.loading && <Loader01c size="s" />}
          <p
            className={twMerge(
              "resp-text--1",
              resp.type == "msg" ? "text-green-400" : "text-red-400",
            )}
          >
            {resp.msg}
          </p>
        </>
      )}
    </div>
  );
};

export const RunRoundsTab = () => {
  const fcon = useFqualContext();
  const { nf, set_nf } = fcon;

  const k = "race_conf.rounds";

  return (
    <>
      <div className="fc-cc resp-text--1 resp-gap-2 my-2">
        <span>
          Finale Created: {getv(nf, "finale_created") ? "true" : "false"}
        </span>
        {getv(nf, "finale_created") == true && (
          <>
            <Link to={`https://fbike.dnaracing.run/race/${nf.finale_rid}`}>
              <Tag className="bg-acc0/40">
                Finale Rid: {getv(nf, "finale_rid")}
              </Tag>
            </Link>
            <span>
              Finale Entry done: {getv(nf, "finale_entry") ? "true" : "false"}
            </span>
          </>
        )}
      </div>

      <DeleteFinale />

      <hr className="my-[2rem]" />

      <table
        className={twMerge("thintdrowp4-table mx-auto w-max resp-text--2")}
      >
        <tbody>
          <tr>
            <td>Auto Finale Race</td>
            <td colSpan={5}>
              <F_OptionInput
                path={`race_conf.auto_finale_race`}
                type="option"
                options={[true, false]}
                txt_fn={(o) => (o ? "true" : "false")}
              />
            </td>
          </tr>

          <tr>
            <td>Prize_usd</td>
            <td colSpan={5}>
              <F_Input path={`race_conf.prize_usd`} type={"n"} />
            </td>
          </tr>

          <tr>
            <td>race version</td>
            <td colSpan={5}>
              <F_OptionInput
                path={`race_conf.version`}
                type={"option"}
                options={[2, 3]}
                txt_fn={(o) => (o == 2 ? "v2-WETH" : o == 3 ? "v3-DEZ" : "--")}
              />
            </td>
          </tr>

          <tr>
            <td>race time</td>
            <td colSpan={5}>
              <F_DateInput path={`race_conf.start_time`} />
            </td>
          </tr>

          <tr>
            <td>race name</td>
            <td colSpan={8}>
              <F_Input
                path={`race_conf.race_name`}
                contprops={{ className: "w-full" }}
                inpprops={{ className: "w-full" }}
              />
            </td>
          </tr>

          <tr className="">
            <td>
              <div className="fr-sc resp-gap-1">
                <span className="text-acc0">Rounds</span>
                <span className="text-white">
                  [{_.values(getv(nf, k) ?? {}).length || 0}]
                </span>

                <Tag
                  onClick={() => {
                    let n = _.values(getv(nf, k) ?? {}).length ?? 0;
                    let new_rounds_conf = {
                      ...(getv(nf, k) || {}),
                      [n + 1]: {},
                    };
                    set_state_ob(nf, set_nf, k, new_rounds_conf);
                  }}
                  className="fc-cc p-2 rounded-full bg-green-500 w-max mx-auto"
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Tag>
              </div>
            </td>

            <td>total in</td>
            <td>in 1 race</td>
            <td>no of races</td>
            <td>payout</td>
            <td>cb</td>
            <td>subpay_type</td>
            <td>subpay_val</td>
            <td>adv_points</td>
          </tr>

          {_.entries(getv(nf, `${k}`) ?? {}).map(([ridx, rconf]) => {
            return (
              <tr>
                <td>
                  <div className="fr-sc resp-gap-1">
                    <span>#{ridx}</span>
                    <Tag
                      className="fc-cc p-2 bg-red-500 rounded-full w-max mx-auto"
                      onClick={() => {
                        let nrounds_conf = _.clone(getv(nf, `${k}`));
                        delete nrounds_conf[ridx];
                        set_state_ob(nf, set_nf, `${k}`, nrounds_conf);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Tag>
                  </div>
                </td>
                <td>
                  <F_Input
                    path={`${k}.${ridx}.tot_in`}
                    type="n"
                    contprops={{ className: "w-[4rem]" }}
                  />
                </td>
                <td>
                  <F_Input
                    path={`${k}.${ridx}.in`}
                    type="n"
                    contprops={{ className: "w-[4rem]" }}
                  />
                </td>
                <td>
                  <F_Input
                    path={`${k}.${ridx}.n`}
                    type="n"
                    contprops={{ className: "w-[4rem]" }}
                  />
                </td>
                <td>
                  <div className="fc-ss">
                    <F_Input
                      path={`${k}.${ridx}.payout`}
                      contprops={{ className: "w-[10rem]" }}
                    />
                  </div>
                </td>
                <td>
                  <div className="fc-ss">
                    <F_Input
                      path={`${k}.${ridx}.cb`}
                      contprops={{ className: "w-[4rem]" }}
                    />
                  </div>
                </td>
                <td>
                  <F_OptionInput
                    path={`${k}.${ridx}.subpay_type`}
                    type={"option"}
                    options={["none", "fixed_usd", "fixed_tok", "%age"]}
                  />
                </td>
                <td>
                  <F_Input path={`${k}.${ridx}.subpay_val`} type={"n"} />
                </td>
                <td>
                  <F_Input path={`${k}.${ridx}.adv_points`} type={"n"} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export const LeaderTab = () => {
  const fcon = useFqualContext();
  const { nf = {}, set_nf, qo_leader } = fcon;

  const by = getv(nf, "scoring.scoring_by") ?? "pos";
  const fl = useMemo(() => {
    if (qissuccesss(qo_leader)) {
      let fl = getv(qo_leader, "data.result") || {};
      return fl;
    }
    return {};
  }, [qo_leader.dataUpdatedAt]);

  // const entry_user_resettable = getv(f, "entry_user_resettable") ?? false;

  return (
    <div className="resp-text--2">
      <table className={twMerge(tablecn.table, "w-full")}>
        <thead>
          <tr>
            <td colSpan={6}>
              <div className="fr-sc resp-text--1">
                <Tag
                  onClick={() => {
                    let quals = _.chain(fl?.qualifiers || [])
                      .filter((e) => e.qualified == true)
                      .map((e) => e.hid)
                      .value();
                    set_state_ob(nf, set_nf, `race_conf.qual_hids`, quals);
                  }}
                  className="bg-acc0"
                >
                  A
                </Tag>
                <span>Select Qualified</span>

                <div class="flex-1"></div>

                <Tag
                  onClick={() => {
                    set_state_ob(nf, set_nf, `race_conf.qual_hids`, []);
                  }}
                  className="bg-red-400"
                >
                  clear
                </Tag>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <div className="fr-sc resp-text--1">
                <span className="my-2 resp-text-1">
                  Selected {(getv(nf, `race_conf.qual_hids`) || []).length}{" "}
                  Bikes
                </span>
              </div>
            </td>
          </tr>
          <tr className={twMerge(tablecn.tr, "text-white font-bold bg-dark")}>
            <td>select</td>
            <td>
              <span className="resp-text--2">Rank</span>
            </td>
            <td>
              <span className="resp-text--2">Bike</span>
            </td>
            <td>
              <span className="resp-text--2">#Tot.Races</span>
            </td>
            <td>
              <span className="resp-text--2">#Sel.Races</span>
            </td>
            <td>
              <span className="resp-text--2">Score</span>
            </td>

            {nf.use_skinteams === true && (
              <>
                <td>
                  <span className="resp-text--2">Skin</span>
                </td>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          <tr></tr>
          {(fl.qualifiers || []).map((qh) => {
            let hdets = fl.hsmap[qh.hid];
            let h = { ...qh, ...hdets };
            h.vault_name = fl.vsmap[hdets.vault];

            let selected = (getv(nf, "race_conf.qual_hids") || []).includes(
              h.hid,
            );

            return (
              <tr
                key={h.hid}
                className={twMerge(
                  tablecn.tr,
                  "resp-text-2",
                  h.qualified == true ? "bg-acc0/20" : "",
                )}
              >
                <td>
                  <div className="fr-sc">
                    <Tag
                      onClick={() => {
                        let new_qual_hids =
                          getv(nf, "race_conf.qual_hids") || [];
                        if (selected) {
                          new_qual_hids = new_qual_hids.filter(
                            (e) => e != h.hid,
                          );
                        } else {
                          new_qual_hids = _.uniq([...new_qual_hids, h.hid]);
                        }
                        set_state_ob(
                          nf,
                          set_nf,
                          `race_conf.qual_hids`,
                          new_qual_hids,
                        );
                      }}
                      className={twMerge(
                        "fc-cc ",
                        selected ? "text-acc0" : " text-slate-800",
                      )}
                    >
                      {selected ? (
                        <FontAwesomeIcon icon={faCheckSquare} />
                      ) : (
                        <FontAwesomeIcon icon={faSquare} />
                      )}
                    </Tag>
                  </div>
                </td>
                <td className={tablecn.td}>
                  <span className="resp-text--2">#{h.rank}</span>
                </td>
                <td className={tablecn.td}>
                  <div className="flex flex-col justify-start items-start">
                    <div className="fr-sc w-full resp-gap-1">
                      <span className="text-acc0 resp-text--1">{h.hid}</span>
                      <span>-</span>
                      <Link to={`https://fbike.dnaracing.run/bike/${h.hid}`}>
                        <span className="font-digi resp-text--1">{h.name}</span>
                      </Link>
                    </div>
                    <Link to={`https://fbike.dnaracing.run/vault/${h.vault}`}>
                      <span className="text-acc0 resp-text--2">
                        {h.vault_name}
                      </span>
                    </Link>
                  </div>
                </td>
                <td className={twMerge(tablecn.td, "resp-text--1 text-right")}>
                  <div className="fr-sc resp-gap-2">
                    <FontAwesomeIcon icon={faFlagCheckered} />
                    <span className="resp-text--1">{dec(h.races_n, 0)}</span>
                  </div>
                </td>
                <td className={twMerge(tablecn.td, "resp-text--1 text-right")}>
                  <div className="fr-sc resp-gap-2">
                    <FontAwesomeIcon icon={faFlagCheckered} />
                    <span className="resp-text--1">
                      {dec(h.sel_races_n, 0)}
                    </span>
                  </div>
                </td>
                <td className={twMerge(tablecn.td, "font-digi")}>
                  <div className="fr-sc resp-gap-1">
                    {by == "prize_eth" ? (
                      <>
                        <FontAwesomeIcon
                          icon={faEthereum}
                          className="text-acc0"
                        />
                        <span className="font-digi text-acc0 resp-text--2">
                          {dec(h.score, 4)}
                        </span>
                      </>
                    ) : by == "prize_usd" ? (
                      <>
                        <FontAwesomeIcon icon={faUsd} className="text-acc0" />
                        <span className="font-digi text-acc0 resp-text--2">
                          {dec(h.score, 2)}
                        </span>
                      </>
                    ) : by == "time" ? (
                      <p className="font-digi text-acc0 resp-text--2 text-right">
                        {h.disp_score}
                      </p>
                    ) : (
                      <span className="resp-text--1 ">{dec(h.score, 2)}</span>
                    )}
                  </div>
                </td>
                {nf.use_skinteams === true && (
                  <>
                    <td>
                      <span
                        className={twMerge(
                          "resp-text--1 font-digi",
                          !nils(h.skinleague) ? "text-acc0" : "text-slate-400",
                        )}
                      >
                        {h.currskin}
                      </span>
                    </td>
                  </>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const ControlRow = () => {
  const history = useNavigate();
  const fcon = useFqualContext();
  const { fid, nf, set_nf, qo_doc, qo_leader } = fcon;

  const f_delete = async () => {
    try {
      if (nils(fid)) return;
      let res = await q_admin_fqual_delete({ fid }).queryFn();
      if (res?.status == "success") {
        await cdelay(1000);
        history("/fqual");
      } else {
        console.log(res);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const f_update = async () => {
    try {
      if (nils(fid)) return;
      let res = await q_admin_fqual_update({ fid, doc: nf }).queryFn();
      if (res?.status == "success") {
        await cdelay(1000);
        if (fid == "new") {
          let nfid = getv(res, "result.fid");
          history(`/fqual/${nfid}`);
        } else window.location.reload();
      } else {
        console.log(res);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const f_reset = async () => {
    try {
      if (nils(fid)) return;
      let f = getv(qo_doc, "data.result") || {};
      set_nf(f);
    } catch (err) {
      console.log(err);
    }
  };

  const f_refetch = async () => {
    try {
      if (nils(fid)) return;
      await qo_doc.refetch();
      await qo_leader.refetch();
      let f = getv(qo_doc, "data.result") || {};
      set_nf(f);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Card className={"w-full bg-reg"}>
      <div className="fr-sc resp-gap-2">
        {fid !== "new" && (
          <Tag
            onClick={f_reset}
            className="fr-sc resp-gap-1 resp-text--1 bg-yellow-600"
          >
            <span>Reset Default</span>
          </Tag>
        )}

        <Tag
          onClick={f_refetch}
          className="fr-sc resp-gap-1 resp-text--1 bg-acc0/40"
        >
          <span>Update to Latest</span>
        </Tag>

        <div className="flex-1"></div>
        <Tag
          onClick={f_delete}
          className="fr-sc resp-gap-1 resp-text--1 bg-red-500"
        >
          <FontAwesomeIcon icon={faTrash} />
          <span>Delete</span>
        </Tag>
        <Tag
          onClick={f_update}
          className="fr-sc resp-gap-1 resp-text--1 bg-green-500"
        >
          <FontAwesomeIcon icon={faSave} />
          <span>Save</span>
        </Tag>
      </div>
    </Card>
  );
};

const PresetRow = () => {
  const [show, set_show] = useState(false);

  const fcon = useFqualContext();
  const { nf, set_nf, qo_doc, qo_leader } = fcon;

  const [qo_presets_list] = useQueries([q_admin_fqual_presets_list()]);
  const list = useMemo(() => {
    return getv(qo_presets_list, "data.result") || [];
  }, [qo_presets_list.dataUpdatedAt]);

  const f_save_preset = async () => {
    set_resp({ loading: true, msg: "Saving Preset" });
    try {
      let preset_name = extract_inp("fqual-preset-name");
      let res = await q_admin_fqual_presets_save({
        preset_name,
        doc: nf,
      }).queryFn();
      if (res?.status == "success") {
        console.log(res);
        let presetid = getv(res, "result.presetid");
        set_resp({
          loading: false,
          status: "success",
          msg: `Saved presetid:${presetid}`,
        });
      } else {
        console.log(res);
        set_resp({ loading: false, status: "error", msg: res.err });
      }
    } catch (err) {
      console.log(err);
      set_resp({ loading: false, status: "error", msg: err.message });
    }
  };

  const use_preset = async (presetid) => {
    try {
      set_resp({ loading: true, msg: "Loading Preset" });
      let res = await q_admin_fqual_presets_get({ presetid }).queryFn();
      console.log(res);
      if (res?.status == "success") {
        let f = getv(res, "result.doc") || {};
        console.log(f);
        if (nils(f)) throw new Error("preset is empty");
        set_nf(f);
        set_resp({ loading: false, status: "success", msg: "Loaded" });
      } else if (res.err) {
        set_resp({ loading: false, status: "error", msg: res.err });
      }
    } catch (err) {
      set_resp({ loading: false, status: "error", msg: err.message });
    }
    set_resp({ ...resp, loading: false });
  };

  const delete_preset = async (presetid) => {
    try {
      set_resp({ loading: true, msg: "Deleting Preset" });
      let res = await q_admin_fqual_presets_delete({ presetid }).queryFn();
      if (res?.status == "success") {
        set_resp({ loading: false, status: "success", msg: "Deleted" });
      } else if (res.err) {
        set_resp({ loading: false, status: "error", msg: res.err });
      }
      setTimeout(qo_presets_list.refetch, 2000);
    } catch (err) {
      set_resp({ loading: false, status: "error", msg: err.message });
    }
  };

  const [resp, set_resp] = useState(undefined);

  return (
    <Card className={"w-full bg-reg resp-text--2"}>
      <div className="fr-sc">
        {resp && (
          <p
            className={twMerge(
              "resp-p-2 fr-sc resp-gap-2",
              resp?.status == "error"
                ? "border-red-300 text-red-300"
                : resp?.status == "success"
                  ? "border-green-300 text-green-300"
                  : "text-white",
            )}
          >
            {resp.loading && <Loader01c size="s" />}
            <span>{resp.msg}</span>
          </p>
        )}
      </div>
      <div className="fr-sc">
        <Tag
          onClick={() => set_show(!show)}
          className={twMerge(show ? "text-yellow-400" : "text-acc0")}
        >
          <span>{show ? "Hide Presets" : "Show Presets"}</span>
        </Tag>
        <div className="flex-1"></div>
        <InpText id="fqual-preset-name" placeholder="Enter Preset Name:" />
        <Tag onClick={f_save_preset} className="bg-green-500">
          <span>Save Preset</span>
        </Tag>
      </div>
      <hr className="my-2" />
      {show && (
        <>
          <div className="fr-sc">
            <Tag
              onClick={() => qo_presets_list.refetch()}
              className="text-orange-300 border-orange-300 fr-sc resp-gap-1"
            >
              {qo_presets_list.isLoading && <Loader01c size="s" />}
              <span>Refresh</span>
            </Tag>
          </div>
          <div className="grid grid-cols-3 gap-2">
            {list.map((e) => {
              return (
                <Card
                  key={e.presetid}
                  className="w-full border border-slate-400 resp-p-2"
                >
                  <div className="fr-sc resp-gap-2">
                    <p className="font-digi">${e.presetid}</p>
                    <span>-</span>
                    <p className="font-digi">{e.preset_name}</p>
                  </div>
                  <div className="fr-sc resp-gap-2">
                    <Tag
                      className="bg-acc0/40"
                      onClick={() => {
                        use_preset(e.presetid);
                      }}
                    >
                      <span>Use</span>
                    </Tag>
                    <div className="flex-1"></div>
                    <Tag
                      className="bg-red-500"
                      onClick={() => {
                        delete_preset(e.presetid);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Tag>
                  </div>
                </Card>
              );
            })}
          </div>
        </>
      )}
    </Card>
  );
};

const SheetDetails = () => {
  const fcon = useFqualContext();
  const { fid } = fcon;

  const [resp, set_resp] = useState({});
  const update_sheet = async () => {
    try {
      set_resp({ loading: true });
      let res = await q_fqual_updatesheet({ fid }).queryFn();
      set_resp(res);
    } catch (err) {
      console.log({
        status: "error",
        msg: err.message,
      });
    }
  };

  return (
    <div class="w-full">
      <table
        className={twMerge(
          "w-full thintdrowp4-table mx-auto w-max resp-text--2",
        )}
      >
        <tbody>
          <tr>
            <td>Sheet link</td>
            <td colSpan={12}>
              <F_Input
                {...{
                  path: "sheet_link",
                  type: "txt",
                  contprops: { className: "min-w-[20rem]" },
                  inpprops: { className: "w-full" },
                }}
              />
            </td>
          </tr>

          <tr>
            <td>Sheet Tab</td>
            <td colSpan={12}>
              <F_Input
                {...{
                  path: "sheet_tab",
                  type: "txt",
                  contprops: { className: "w-full" },
                  inpprops: { className: "w-full" },
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <div class="fr-cc my-2">
        <div class="flex-1">
          <p className="resp-text--1">{jstr(resp)}</p>
        </div>
        <Tag onClick={update_sheet} className="bg-acc0/40 font-digi">
          Update from Sheet
        </Tag>
      </div>
    </div>
  );
};

export const FqualPage = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const [tab, set_tab] = useState(psearch.tab ?? "info");

  const { fid } = useParams();
  const [qo_doc, qo_leader] = useQueries([
    q_admin_fqual_doc({ fid }, { enabled: fid != "new" }),
    q_admin_fqual_leader({ fid }, { enabled: fid != "new" }),
  ]);

  const [nf, set_nf] = useState(fid == "new" ? {} : undefined);
  const f = useMemo(() => {
    if (qissuccesss(qo_doc)) {
      let f = getv(qo_doc, "data.result") || {};
      if (nf === undefined) set_nf(f);
      return f;
    }
    return {};
  }, [qo_doc.dataUpdatedAt]);

  useEffect(() => {
    upd_psearch({ ...psearch, tab });
  }, [tab]);

  const fcon = {
    nf,
    set_nf,

    fid,
    tab,
    set_tab,
    qo_doc,
    qo_leader,
  };

  return (
    <FqualContext.Provider value={fcon}>
      <div className="h-page">
        <div className="max-w-[98vw] w-[60rem] mx-auto">
          <div className="h-[2rem]"></div>
          <PresetRow />

          <div className="fr-sc">
            {fid == "new" ? (
              <p className="text-center resp-text-1 text-orange-300 font-digi">
                Create New FQual
              </p>
            ) : (
              <p className="text-center resp-text-1 text-acc0 font-digi">
                FQual - {fid}
              </p>
            )}
            <div className="flex-1"></div>
            <Tag
              redirect={`https://fbike.dnaracing.run/fqual/${fid}`}
              className="text-acc0 border-acc0"
            >
              <FontAwesomeIcon icon={faLink} />
            </Tag>
          </div>

          {(qo_doc.isLoading || qo_leader.isLoading) && <Loader01c />}

          {((qissuccesss(qo_doc) && qissuccesss(qo_leader)) ||
            fid == "new") && (
            <>
              <ControlRow />

              <div className="fr-cc resp-gap-2">
                {[
                  "info",
                  "signup",
                  "leaderboard",
                  "run_rounds",
                  "finc",
                  "details",
                ].map((_st) => {
                  return (
                    <Tag
                      onClick={() => set_tab(_st)}
                      className={twMerge(
                        "resp-text--1 font-digi transition duration-300",
                        _st == tab ? "bg-acc0/50" : "bg-transparent text-white",
                      )}
                    >
                      {_.capitalize(_st)}
                    </Tag>
                  );
                })}
              </div>

              <div className="h-[2rem]"></div>

              {tab == "info" && <InfoTab />}
              {tab == "signup" && <SignupTab />}
              {tab == "run_rounds" && <RunRoundsTab />}
              {tab == "leaderboard" && (
                <ErrorBoundary>
                  <LeaderTab />
                </ErrorBoundary>
              )}
              {tab == "finc" && <FincSection {...{ f }} />}
              {tab == "details" && (
                <>
                  <SheetDetails />
                </>
              )}
            </>
          )}

          <div className="h-[5rem]"></div>
        </div>
      </div>
    </FqualContext.Provider>
  );
};
