import _ from "lodash";
import crypto from "crypto-js";
import moment from "moment";
import { ethers } from "ethers";

export const cdelay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const getv = (ob, path) => {
  try {
    let a = _.get(ob, path);
    if (a === undefined) return undefined;
    return a;
  } catch (err) {
    return undefined;
  }
};

export const jparse = (json, def = undefined) => {
  try {
    let s = JSON.parse(json);
    if (nils(s)) return def;
    return s;
  } catch (err) {
    return def;
  }
};
export const jstr = (jsonob, def = undefined) => {
  try {
    return JSON.stringify(jsonob);
  } catch (err) {
    return def;
  }
};

export const nils = (s) => [null, undefined, NaN, ""].includes(s);

export const get_inp = (id, type = "txt") => {
  let el = document.getElementById(id);
  if (!el) return undefined;
  let val = el.value;
  if (!val) return undefined;
  if (type == "n") {
    val = parseFloat(val);
    if (!_.isNaN(val)) return val;
    else return undefined;
  }
  return val;
};
export const set_inp = (id, txt = "") => {
  let el = document.getElementById(id);
  if (!el) return;
  el.value = txt;
};

export const cbsk = [10, 12, 14, 16, 18, 20, 22];

export const json_to_base64 = (o) => {
  if (nils(o)) return "";
  let m = jstr(o);
  m = crypto.enc.Utf8.parse(m);
  m = crypto.enc.Base64url.stringify(m);
  return m;
};

export const base64_to_json = (m) => {
  if (nils(m)) return "";
  m = crypto.enc.Base64url.parse(m);
  m = crypto.enc.Utf8.stringify(m);
  return jparse(m);
};

export const dec = (n, d = 2) => {
  if (n === null) return "null";
  if (!_.isNumber(parseFloat(n))) return;
  return parseFloat(n).toFixed(d);
};

export const copy_clip = (str) => {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};
export const copy_to_sheet_table = (ar, keys) => {
  let str = "";

  for (let k of keys) str += `${k}	`;
  str += "\n";
  for (let row of ar) {
    for (let k of keys) str += `${row[k]}	`;
    str += "\n";
  }
  copy_clip(str);
};

export const emp = (o) => {
  return _.chain(o)
    .values()
    .filter((e) => !nils(e))
    .isEmpty()
    .value();
};

export const iso = (d = new Date()) => {
  try {
    if (parseInt(d) == d) d = parseInt(d);
    return new Date(d).toISOString();
  } catch (err) {
    return "iso-err";
  }
};

export const nano = (d = Date.now()) => {
  try {
    return new Date(d).getTime();
  } catch (err) {
    return "nano-err";
  }
};

export const pad = (n, d = 2) => {
  if (!_.isNumber(parseFloat(n))) return;
  return parseFloat(n).toString().padStart(d, "0");
};

export const time_diff_txt = (st, ed) => {
  st = nano(st);
  ed = nano(ed);
  let rem = ed > st ? ed - st : st - ed;
  // let cn = rem < hot ? "text-red-400" : "text-green-400";
  rem = rem / 1000;
  let s = parseInt(rem % 60);
  rem = rem / 60;
  let m = parseInt(rem % 60);
  rem = rem / 60;
  let h = parseInt(rem % 24);
  rem = rem / 24;
  let d = parseInt(rem);
  let ar = [
    [d, "d", "days"],
    [h, "h", "hrs"],
    [m, "m", "mins"],
    [s, "s", "sec"],
  ];
  let highnil = _.findIndex(ar, (e) => e[0] !== 0);
  let txt = ``;
  for (let [a, b] of ar.slice(highnil)) txt += `${pad(a, 2)}${b} `;
  return [ar, highnil, txt];
};

export const to_time_mini = (date, absolute = false) => {
  let now = nano();
  let [ar, idx, txt] = time_diff_txt(now, date);
  if (absolute) {
    return txt;
  } else {
    if (idx <= 2) {
      let row = ar[idx];
      if (!row) return "--";
      let a = `${pad(row[0], 2)} ${row[2]}`;
      row = ar[idx + 1];
      if (row) a += ` ${pad(row[0], 2)} ${row[2]}`;
    } else {
      return `${txt}`;
    }
  }
};

export const from_time_mini = (date, absolute = false) => {
  let now = nano();
  let [ar, idx, txt] = time_diff_txt(date, now);
  if (absolute) {
    return txt;
  } else {
    if (idx < 2) {
      let row = ar[idx];
      if (!row) return "--";
      let a = `${pad(row[0], 2)} ${row[2]}`;
      row = ar[idx + 1];
      if (row) a += ` ${pad(row[0], 2)} ${row[2]}`;
      return a;
    } else {
      return `${txt}`;
    }
  }
};

export const trim_n = (a, n = 10) => {
  try {
    a = a.toString();
    let len = a.length;
    return `${a.slice(0, n)}${len > n ? "..." : ""}`;
  } catch (err) {
    return "---";
  }
};

export const iso_format = (d, format = `DD-MM-YYYY HH:mm:ssA`) => {
  try {
    return moment(new Date(d)).format(format);
  } catch (err) {
    console.log("iso_format", d, err.messsage);
    return "invalid-date";
  }
};

export const toeth = (n) => ethers.parseEther(n.toString());

export const tofeth = (n) => ethers.formatEther(n.toString());

export const snake_str_to_caps = (s) =>
  _.chain(s).split("-").map(_.capitalize).join(" ").value();
