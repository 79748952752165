import React from "react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useQueries } from "react-query";
import {
  qiserr,
  qissuccesss,
  q_admin_challenge_list,
  q_admin_challenge_doc,
  q_admin_challenge_leader,
  q_admin_challenge_delete,
  q_admin_challenge_update,
  q_admin_challenge_duplicate,
} from "../queries/queries.js";
import {
  base64_to_json,
  cdelay,
  copy_to_sheet_table,
  dec,
  emp,
  getv,
  iso,
  iso_format,
  json_to_base64,
  jstr,
  nils,
  set_inp,
} from "../utils/utils.js";
import {
  gen_filters_from_valob,
  gen_valob_from_filters,
} from "../utils/filt.js";
import { tokdecn2, useAppContext } from "../App.js";
import { Loader01c } from "../components/anims.js";
import _, { set } from "lodash";
import { Card, Img, InpText, Tag } from "../components/utilityComps.js";
import {
  F_DateInput,
  F_Input,
  F_InputRange,
  F_InputTextAr,
  F_OptionInput,
  InpAutoWrap,
  set_state_ob,
} from "../components/input.js";
import { twMerge } from "tailwind-merge";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { get_payout_txt, pos_txt, tablecn } from "../utils/cn_map.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBox,
  faCheckSquare,
  faClone,
  faClose,
  faFlagCheckered,
  faLink,
  faPlus,
  faRefresh,
  faSave,
  faSquare,
  faSync,
  faThumbsDown,
  faThumbsUp,
  faTrash,
  faUsd,
} from "@fortawesome/free-solid-svg-icons";
import { faEmpire, faEthereum } from "@fortawesome/free-brands-svg-icons";
import { polychainimg } from "../utils/links.js";
import { ErrorBoundary } from "../components/errbou.js";

const ChallengeContext = createContext();
export const useChallengeContext = () => useContext(ChallengeContext);

export const ChallengeListPage = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;
  const bfilt = useMemo(() => {
    let f = psearch.f;
    if (nils(f)) return {};
    return base64_to_json(f);
  }, [psearch]);

  const [filt, set_filt] = useState({
    date: {
      label: "Date",
      vals: bfilt?.date ?? { mi: null, mx: null },
      type: "date-range",
    },
    status: {
      label: "Status",
      vals: !_.isEmpty(bfilt.status) ? bfilt.status : [],
      type: "options",
      cn: "resp-text--2 font-digi bg-acc0/10 text-white",
      active_cn: (active, op) => (active ? "bg-acc0/50" : ""),
      options: ["yettostart", "active", "ended"],
    },
  });

  const [searchtxt, set_searchtxt] = useState("");
  const valfilt = useMemo(() => {
    let o = gen_valob_from_filters(filt);
    return o;
  }, [jstr(filt)]);
  useMemo(() => {
    upd_psearch({ f: json_to_base64(valfilt) });
  }, [searchtxt, jstr(valfilt)]);

  const [qo_list] = useQueries([q_admin_challenge_list({ filt: valfilt })]);
  const chlg_s = useMemo(() => {
    if (!qissuccesss(qo_list)) return [];
    return getv(qo_list, "data.result") || [];
  }, [qo_list.dataUpdatedAt]);
  const ids = useMemo(() => _.map(chlg_s, "id"), [jstr(chlg_s)]);
  const view_ids = useMemo(() => {
    if (nils(searchtxt)) return _.map(chlg_s, "fid");
    return _.chain(chlg_s)
      .filter((e) => {
        if (nils(e.name)) return false;
        let n = e.name.toLowerCase();
        return n.includes(searchtxt.toLowerCase());
      })
      .map("fid")
      .value();
  }, [jstr(chlg_s), searchtxt]);

  const inpargs = { filters: filt, set_filters: set_filt, fkey: "fquallist" };
  const clear_filt = () => {
    let nfilt = gen_filters_from_valob(filt, {});
    set_filt(nfilt);
  };

  return (
    <div className="h-page">
      <Helmet>
        <title>Challenges || Admin</title>
      </Helmet>
      <div className="max-w-[98vw] w-[60rem] mx-auto">
        <div className="h-[5rem]"></div>
        <p className="text-center resp-text-2 text-acc0 font-digi">
          Challenges
        </p>
        <div className="h-[1rem]"></div>

        <Card className="bg-reg w-full">
          <InpAutoWrap {...{ ...inpargs, idd: "date" }} />
          <InpAutoWrap {...{ ...inpargs, idd: "status" }} />
          <div className="fr-sc">
            <Tag onClick={clear_filt} className="bod-red resp-text--2">
              clear filt
            </Tag>
          </div>
        </Card>

        <div className="fr-sc resp-gap-2 my-2">
          <InpText
            {...{
              id: "challenge-search",
              placeholder: "search",
              contprops: { className: "w-[20rem] bg-reg" },
              inpprops: { className: "w-full" },
              setter: (v) => {
                if (nils(v)) v = null;
                set_searchtxt(v);
              },
            }}
          />
          <span>filtered {view_ids.length}</span>
          <span>/</span>
          <span>{ids.length} Challenges</span>
          <div className="flex-1"></div>
          <Tag
            redirect="/challenge/new"
            className="bg-acc0/40 font-digi resp-text--1"
          >
            <span>New Challenge</span>
          </Tag>
        </div>

        {qo_list.isLoading ? (
          <Loader01c size="xs" />
        ) : qiserr(qo_list) ? (
          <p className="text-center resp-text--1 text-red-400">
            {qiserr(qo_list)}
          </p>
        ) : qissuccesss(qo_list) && _.isEmpty(view_ids) ? (
          <p className="text-center resp-text--1 text-yellow-300">
            No Challenges
          </p>
        ) : qissuccesss(qo_list) && !_.isEmpty(view_ids) ? (
          <div>
            {chlg_s.map((doc, idx) => {
              if (_.isEmpty(doc)) return null;

              let id = getv(doc, "id");

              return (
                <Link target="_blank" to={`/challenge/${id}`}>
                  <Card className={twMerge("bg-reg w-full resp-text--2")}>
                    <div className="fr-sc resp-gap-2">
                      <div class="fc-ss flex-1">
                        <div class="fr-sc resp-gap-2">
                          <span className="text-acc0">{id}</span>
                          <span className="resp-text--1 font-digi">
                            {doc.name}
                          </span>
                          <div class="flex-1"></div>
                        </div>
                      </div>
                      <Tag className="bg-acc0/30 -skew-x-12">
                        <span className="resp-text--1">
                          {_.upperCase(doc.status)}
                        </span>
                      </Tag>

                      <div class="fc-ss w-[20rem]">
                        <div class="grid grid-cols-2 gap-2">
                          <div class="">CREATED</div>
                          <div class="">
                            {iso_format(getv(doc, "create_time"))}
                          </div>

                          <div class="">STARTED</div>
                          <div class="">
                            {iso_format(getv(doc, "start_time"))}
                          </div>

                          <div class="">ENDED</div>
                          <div class="">
                            {iso_format(getv(doc, "end_time"))}
                          </div>
                        </div>

                        {doc.status == "ended" && (
                          <span>{iso_format(getv(doc, "end_time"))}</span>
                        )}
                      </div>
                    </div>
                  </Card>
                </Link>
              );
            })}
          </div>
        ) : null}

        <div className="h-[5rem]"></div>
      </div>
    </div>
  );
};

const fee_segs_ks = [
  "1_00usd",
  "1_50usd",
  "2_00usd",
  "2_50usd",
  "3_00usd",
  "xchampion_leader",
];

const upgrade_modes = ["on_wins", "thresh_profit_usd"];
const downgrade_modes = ["on_end", "consec_lose_prize"];

const ControlRow = () => {
  const history = useNavigate();
  const fcon = useChallengeContext();
  const { id, nf, set_nf, qo_doc, qo_leader } = fcon;

  const f_delete = async () => {
    try {
      if (nils(id)) return;
      let res = await q_admin_challenge_delete({ id }).queryFn();
      if (res?.status == "success") {
        await cdelay(1000);
        history("/fqual");
      } else {
        console.log(res);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const f_duplicate = async () => {
    try {
      if (nils(id) || id == "new") return;
      let res = await q_admin_challenge_duplicate({ id }).queryFn();
      if (res?.status == "success") {
        await cdelay(2 * 1e3);
        let new_id = getv(res, "result.id");
        window.open(`/challenge/${new_id}`);
      } else {
        console.log(res);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const f_update = async () => {
    try {
      if (nils(id)) return;
      console.log("f_update", nf);
      let doc2 = _.cloneDeep(nf);
      let res = await q_admin_challenge_update({ id, doc: doc2 }).queryFn();
      if (res?.status == "success") {
        await cdelay(2 * 1e3);
        if (id == "new") {
          let new_id = getv(res, "result.id");
          history(`/challenge/${new_id}`);
        } else {
          window.location.reload();
        }
      } else {
        console.log(res);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const f_reset = async () => {
    try {
      if (nils(id)) return;
      let f = getv(qo_doc, "data.result") || {};
      set_nf(f);
    } catch (err) {
      console.log(err);
    }
  };

  const f_refetch = async () => {
    try {
      if (nils(id)) return;
      await qo_doc.refetch();
      await qo_leader.refetch();
      let f = getv(qo_doc, "data.result") || {};
      set_nf(f);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Card className={"w-full bg-reg"}>
      <div className="fr-sc resp-gap-2">
        {id !== "new" && (
          <Tag
            onClick={f_reset}
            className="fr-sc resp-gap-1 resp-text--1 bg-yellow-600"
          >
            <span>Reset Default</span>
          </Tag>
        )}

        <Tag
          onClick={f_refetch}
          className="fr-sc resp-gap-1 resp-text--1 bg-acc0/40"
        >
          <span>Update to Latest</span>
        </Tag>

        <div className="flex-1"></div>
        <Tag
          onClick={f_delete}
          className="fr-sc resp-gap-1 resp-text--1 bg-red-500"
        >
          <FontAwesomeIcon icon={faTrash} />
          <span>Delete</span>
        </Tag>
        <div class="w-[2rem]"></div>
        {!nils(id) && id != "new" && (
          <Tag
            onClick={f_duplicate}
            className="fr-sc resp-gap-1 resp-text--1 bg-orange-500"
          >
            <FontAwesomeIcon icon={faClone} />
            <span>Duplicate</span>
          </Tag>
        )}
        <Tag
          onClick={f_update}
          className="fr-sc resp-gap-1 resp-text--1 bg-green-500"
        >
          <FontAwesomeIcon icon={faSave} />
          <span>Save</span>
        </Tag>
      </div>
    </Card>
  );
};

export const InfoTab = () => {
  const fcon = useChallengeContext();
  const { nf, set_nf } = fcon;
  const inpargs = { nf, set_nf, nfkey: "challengeinfo" };

  const use_default_name = () => {
    let {
      cb = "00",
      payout = "--",
      segment = "---",
    } = getv(nf, "cri", {}) ?? {};
    let payout_txt = get_payout_txt(payout);
    let segtxt = _.isArray(segment) ? segment.join(" ") : segment;
    return `${cb} / ${payout_txt} / ${segtxt}`;
  };

  const gen_typeid = () => {
    let {
      cb = "00",
      payout = "--",
      segment = "---",
    } = getv(nf, "cri", {}) ?? {};
    let payout_txt = get_payout_txt(payout);
    let ef = getv(nf, "cri.entry_filters") ?? {};

    let segtxt = [];
    if (!emp(ef.fno)) {
      let { mi = 1, mx = 900 } = ef.fno;
      let ftxt = `F${mi}-${mx}`;
      segtxt.push(ftxt);
    }
    if (!_.isEmpty(ef.type)) segtxt = segtxt.concat(ef.type);
    if (!_.isEmpty(ef.element)) segtxt = segtxt.concat(ef.element);
    if (!_.isEmpty(ef.gender)) segtxt = segtxt.concat(ef.gender);
    segtxt = segtxt.filter((e) => !nils(e)).map((e) => _.capitalize(e));

    let typeid =
      `${cb}|${payout_txt.replaceAll(" ", "_")}|${segtxt.join("_")}`.toLowerCase();
    return [typeid, segtxt.join(" ")];
  };

  const use_default_typeid = () => {
    let [ntypeid, nsegtxt] = gen_typeid();
    return ntypeid;
  };

  const use_default_segtxt = () => {
    let [ntypeid, nsegtxt] = gen_typeid();
    return nsegtxt;
  };

  const get_sorted_fee_keys = (ks) => {
    return _.chain(ks)
      .sortBy((e) => {
        return _.indexOf(fee_segs_ks, e);
        // if (e == "xchampion_leader") return 1e4;
        // if (e.endsWith("usd")) {
        //   let v = parseFloat(e.slice(0, -3).replace("_", "."));
        //   return parseFloat(v);
        // }
        // return 0;
      })
      .value();
  };

  const update_fee_segs_ks = () => {
    let ks = getv(nf, "fee_segs_ks", []);
    let ks2 = get_sorted_fee_keys(ks);
    if (jstr(ks2) !== jstr(ks)) {
      console.log("update_fee_segs_ks", ks2);
      set_state_ob(nf, set_nf, "fee_segs_ks", ks2);
      return;
    }

    let exisks = _.keys(getv(nf, "fee_segs", {}));
    let miss = _.difference(ks, exisks);
    for (let k of miss) {
      set_state_ob(nf, set_nf, `fee_segs.${k}`, {});
    }
  };

  const active_feekeys = useMemo(() => {
    return get_sorted_fee_keys(_.keys(getv(nf, "fee_segs", {})));
  }, [jstr(getv(nf, "fee_segs"))]);

  useEffect(() => {
    update_fee_segs_ks();
  }, [jstr(getv(nf, "fee_segs_ks"))]);

  const ext = { active_feekeys };

  return (
    <div className="w-full overflow-auto">
      <table
        className={twMerge("thintdrowp4-table mx-auto w-full resp-text--2")}
      >
        <tbody>
          <tr>
            <td>name</td>
            <td colSpan={12}>
              <div class="fr-sc resp-gap-2">
                <F_Input
                  {...{
                    ...inpargs,
                    path: "name",
                    type: "txt",
                    contprops: { className: "flex-1 max-w-[30rem]" },
                    inpprops: { className: "w-full" },
                  }}
                />
                <Tag
                  onClick={() => {
                    set_state_ob(nf, set_nf, "name", use_default_name());
                  }}
                  className="w-max bg-acc0/30"
                >
                  Use Default
                </Tag>
              </div>
            </td>
          </tr>

          <tr>
            <td>typeid</td>
            <td colSpan={12}>
              <div class="fr-sc resp-gap-2">
                <F_Input
                  {...{
                    ...inpargs,
                    path: "typeid",
                    type: "txt",
                    contprops: { className: "flex-1 max-w-[30rem]" },
                    inpprops: { className: "w-full" },
                  }}
                />
                <Tag
                  onClick={() => {
                    set_state_ob(nf, set_nf, "typeid", use_default_typeid());
                  }}
                  className="w-max bg-acc0/30"
                >
                  Use Default
                </Tag>
              </div>
            </td>
          </tr>

          <tr className="">
            <td>
              <span>Start Time</span>
            </td>
            <td colSpan={6}>
              <div class="fr-sc w-full">
                <F_DateInput
                  {...{
                    ...inpargs,
                    path: "start_time",
                  }}
                />
              </div>
            </td>
          </tr>

          <tr>
            <td>status</td>
            <td colSpan={12}>
              <F_OptionInput
                {...{
                  ...inpargs,
                  path: "status",
                  type: "option",
                  options: ["yettostart", "active", "ended", "duplicate"],
                }}
              />
            </td>
          </tr>

          <tr>
            <td>Hide</td>
            <td colSpan={12}>
              <F_OptionInput
                {...{
                  ...inpargs,
                  path: "hide",
                  type: "option",
                  options: [true, false],
                  txt_fn: (o) => (o ? "HIDE" : "Dont Hide"),
                }}
              />
            </td>
          </tr>

          <tr>
            <td>CB</td>
            <td colSpan={12}>
              <F_OptionInput
                {...{
                  ...inpargs,
                  path: "cri.cb",
                  type: "option",
                  options: [10, 14, 16, 18, 20, 22],
                }}
              />
            </td>
          </tr>

          <tr>
            <td>Payout Text</td>
            <td colSpan={12}>
              <div className="fc-ss">
                <F_OptionInput
                  {...inpargs}
                  path={`cri.payout`}
                  type="option"
                  options={["wta", "top2", "top3", "top4", "dblup", "pity"]}
                />
                <F_Input {...inpargs} path={`cri.payout`} />
                <p className="resp-text--3">{jstr(getv(nf, `cri.payout`))}</p>
              </div>
            </td>
          </tr>

          <tr>
            <td>Segment</td>
            <td colSpan={12}>
              <div class="fr-sc resp-gap-2">
                <F_Input
                  {...{
                    ...inpargs,
                    path: "cri.segment",
                    type: "txt",
                    contprops: { className: "flex-1 max-w-[30rem]" },
                    inpprops: { className: "w-full" },
                  }}
                />
                <Tag
                  onClick={() => {
                    set_state_ob(
                      nf,
                      set_nf,
                      "cri.segment",
                      use_default_segtxt(),
                    );
                  }}
                  className="w-max bg-acc0/30"
                >
                  Use Default
                </Tag>
              </div>
            </td>
          </tr>

          <tr>
            <td>Criteria</td>
            <td colSpan={12}>
              <table className="w-full thintdrowp4-table">
                <tbody>
                  <tr>
                    <td>element</td>
                    <td>
                      <F_OptionInput
                        {...inpargs}
                        path={`cri.entry_filters.element`}
                        type="options"
                        options={["metal", "fire", "earth", "water"]}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>type</td>
                    <td>
                      <F_OptionInput
                        {...inpargs}
                        path={`cri.entry_filters.type`}
                        type="options"
                        options={[
                          "genesis",
                          "morphed",
                          "freak",
                          "xclass",
                          "trainer",
                        ]}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Gender</td>
                    <td>
                      <F_OptionInput
                        {...inpargs}
                        path={`cri.entry_filters.gender`}
                        type="options"
                        options={["male", "female"]}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>F.No.</td>
                    <td>
                      <div className="fc-ss">
                        <F_Input
                          {...inpargs}
                          path={`cri.entry_filters.fno`}
                          disp_txt={(v) => {
                            if (nils(v)) return "";
                            else if (_.isArray(v)) return v.join(",");
                            else if (_.isObject(v)) return `${v.mi}-${v.mx}`;
                            else return v;
                          }}
                          el_postprocess_fn={(e) => {
                            if (nils(e)) return null;
                            else if (e.includes(","))
                              return e.split(",").map((e) => parseInt(e));
                            else if (e.includes("-"))
                              return {
                                mi: parseInt(e.split("-")[0]),
                                mx: parseInt(e.split("-")[1]),
                              };
                            else return [parseInt(e)];
                          }}
                        />
                        <p className="resp-text--3">
                          {jstr(getv(nf, `cri.entry_filters.fno`))}
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td>RV Mode</td>
            <td colSpan={12}>
              <F_OptionInput
                {...{
                  ...inpargs,
                  path: "rvmode",
                  type: "option",
                  options: ["car", "bike", "horse"],
                }}
              />
            </td>
          </tr>

          <tr>
            <td>
              <span className="text-acc0">Title Rules</span>
              <span className="text-white">
                [{getv(nf, "title_rules")?.length || 0} Rules]
              </span>
            </td>
            <td>
              <div class="fr-sc w-full">
                <Tag
                  onClick={() => {
                    let new_trules = [
                      ...(getv(nf, "title_rules") || []),
                      ["", ""],
                    ];
                    set_state_ob(nf, set_nf, "title_rules", new_trules);
                  }}
                  className="fc-cc p-2 rounded-full bg-green-500 w-max mx-auto"
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Tag>
              </div>
            </td>
          </tr>
          {(getv(nf, "title_rules") || []).map((e, i) => {
            return (
              <tr key={`title_rules-${i}`}>
                <td>
                  <div className="fr-sc resp-gap-2 rounded-lg p-2 bg-dark w-max mr-auto">
                    <Tag>#{i + 1}</Tag>
                    <div className="flex-1"></div>
                    <Tag
                      onClick={() => {
                        let new_trules = _.chain(getv(nf, "title_rules"))
                          .filter((e, idx) => idx != i)
                          .value();

                        set_state_ob(nf, set_nf, "title_rules", new_trules);
                      }}
                      className="fc-cc p-2 rounded-full bg-red-500 w-max mx-auto"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Tag>
                  </div>
                </td>
                <td colSpan={12}>
                  <div className="fr-sc">
                    <F_Input
                      {...{ ...inpargs }}
                      path={`title_rules.${i}.0`}
                      contprops={{ className: "w-[12rem]" }}
                      inpprops={{ className: "w-full" }}
                    />

                    <F_Input
                      {...{ ...inpargs }}
                      path={`title_rules.${i}.1`}
                      contprops={{ className: "flex-1" }}
                      inpprops={{ className: "w-full" }}
                    />
                  </div>
                </td>
              </tr>
            );
          })}

          <tr>
            <td>fee segments </td>
            <td colSpan={12}>
              <div class="fc-ss">
                <div class="">
                  <F_OptionInput
                    {...{
                      ...inpargs,
                      path: "fee_segs_ks",
                      type: "options",
                      options: [...fee_segs_ks],
                      txt_fn: (o) => {
                        return o;
                        // let r = fee_txt_fn(o);
                        // return getv(r, "0") ?? o;
                      },
                    }}
                  />
                </div>
                <div class="">
                  <span>{jstr(active_feekeys)}</span>
                </div>
              </div>
            </td>
          </tr>

          {active_feekeys.map((k) => {
            return (
              <React.Fragment key={k}>
                <FeeSegConfig {...{ ext, fk: k }} />
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const FeeSegConfig = ({ fk, ext = {} }) => {
  const fcon = useChallengeContext();
  const { nf, set_nf } = fcon;

  const fkname = useMemo(() => {
    let r = fee_txt_fn(fk);
    return getv(r, "0") ?? fk;
  }, [fk]);

  const delete_fee_seg = () => {
    let fee_segs = getv(nf, "fee_segs", {});
    delete fee_segs[fk];
    set_state_ob(nf, set_nf, "fee_segs", fee_segs);
  };

  const inpargs = { nf, set_nf, nfkey: `challengeinfo:${fk}` };

  const format = getv(nf, `fee_segs.${fk}.format`);

  const k = `fee_segs.${fk}.rounds_conf`;

  return (
    <>
      <tr>
        <td>
          <span className="font-digi resp-text--1 text-acc0">
            FeeSeg: {fkname}
          </span>
        </td>
        <td colSpan={12}>
          <div class="fr-sc">
            <Tag
              onClick={delete_fee_seg}
              className="bg-red-500/40 hover:bg-red-500"
            >
              <FontAwesomeIcon icon={faTrash} />
            </Tag>
          </div>
        </td>
      </tr>

      <tr>
        <td>
          <p className="px-4">format</p>
        </td>
        <td colSpan={10}>
          <div class="fr-sc w-full">
            <F_OptionInput
              {...{
                ...inpargs,
                path: `fee_segs.${fk}.format`,
                type: "option",
                options: ["normal", "rounds"],
              }}
            />
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <p className="px-4">RGate</p>
        </td>
        <td colSpan={10}>
          <div class="fr-sc w-full">
            <F_Input
              {...{
                ...inpargs,
                path: `fee_segs.${fk}.rgate`,
                type: "n",
                contprops: { className: "w-[4rem]" },
                inpprops: { className: "w-full" },
              }}
            />
          </div>
        </td>
      </tr>

      <tr>
        <td>
          <p className="px-4">payout</p>
        </td>
        <td colSpan={10}>
          <div class="fr-sc w-full">
            <F_OptionInput
              {...{
                ...inpargs,
                path: `fee_segs.${fk}.payout`,
                type: "option",
                options: ["wta", "top2", "top3", "top4", "dblup", "pity"],
              }}
            />
          </div>
        </td>
      </tr>

      <tr>
        <td>
          <p className="px-4">Rake [default: 0.15]</p>
        </td>
        <td colSpan={10}>
          <div class="fr-sc w-full">
            <F_Input
              {...{
                ...inpargs,
                path: `fee_segs.${fk}.rake`,
                type: "n",
                contprops: { className: "w-[4rem]" },
                inpprops: { className: "w-full" },
              }}
            />
          </div>
        </td>
      </tr>

      <tr>
        <td>
          <p className="px-4">POT_Rake [default: 0.12]</p>
        </td>
        <td colSpan={10}>
          <div class="fr-sc w-full">
            <F_Input
              {...{
                ...inpargs,
                path: `fee_segs.${fk}.pot_rake`,
                type: "n",
                contprops: { className: "w-[4rem]" },
                inpprops: { className: "w-full" },
              }}
            />
          </div>
        </td>
      </tr>

      <tr>
        <td>
          <div class="fc-ss">
            <p className="px-4">Upgrade</p>
          </div>
        </td>
        <td colSpan={10}>
          <div class="fr-sc resp-gap-2">
            <Tag
              onClick={() => {
                set_state_ob(nf, set_nf, `fee_segs.${fk}.upgrade`, {});
              }}
              className="bg-red-500/40 hover:bg-red-500"
            >
              <FontAwesomeIcon icon={faTrash} />
            </Tag>
            <Tag
              onClick={() => {
                let emp = _.isEmpty(getv(nf, `fee_segs.${fk}.upgrade`));
                console.log("emp", emp);
                if (emp !== true) return;
                let o = { mode: null, to: null, req: 1 };
                set_state_ob(nf, set_nf, `fee_segs.${fk}.upgrade`, o);
              }}
              className="bg-green-500/40 hover:bg-green-500"
            >
              <FontAwesomeIcon icon={faPlus} />
            </Tag>
          </div>
          {!_.isEmpty(getv(nf, `fee_segs.${fk}.upgrade`)) && (
            <div class="fc-ss resp-gap-2">
              <div class="fr-sc resp-gap-2 border-b border-slate-400">
                <p className="w-[6rem] text-left">Mode:</p>
                <F_OptionInput
                  {...{
                    ...inpargs,
                    path: `fee_segs.${fk}.upgrade.mode`,
                    type: "option",
                    options: upgrade_modes,
                  }}
                />
              </div>

              <div class="fr-sc resp-gap-2 border-b border-slate-400">
                <p className="w-[6rem] text-left">Req:</p>
                <F_Input
                  {...{
                    ...inpargs,
                    path: `fee_segs.${fk}.upgrade.req`,
                    type: "n",
                    contprops: { className: "w-[4rem]" },
                    inpprops: { className: "w-full" },
                  }}
                />
              </div>

              <div class="fr-sc resp-gap-2 border-b border-slate-400">
                <p className="w-[6rem] text-left">To:</p>
                <F_OptionInput
                  {...{
                    ...inpargs,
                    path: `fee_segs.${fk}.upgrade.to`,
                    type: "option",
                    options: getv(ext, "active_feekeys"),
                  }}
                />
              </div>
            </div>
          )}
        </td>
      </tr>

      <tr>
        <td>
          <div class="fc-ss">
            <p className="px-4">Downgrade</p>
          </div>
        </td>
        <td colSpan={10}>
          <div class="fr-sc resp-gap-2">
            <Tag
              onClick={() => {
                set_state_ob(nf, set_nf, `fee_segs.${fk}.downgrade`, {});
              }}
              className="bg-red-500/40 hover:bg-red-500"
            >
              <FontAwesomeIcon icon={faTrash} />
            </Tag>
            <Tag
              onClick={() => {
                let emp = _.isEmpty(getv(nf, `fee_segs.${fk}.downgrade`));
                console.log("emp", emp);
                if (emp !== true) return;
                let o = { mode: null, to: null, req: 1 };
                set_state_ob(nf, set_nf, `fee_segs.${fk}.downgrade`, o);
              }}
              className="bg-green-500/40 hover:bg-green-500"
            >
              <FontAwesomeIcon icon={faPlus} />
            </Tag>
          </div>
          {!_.isEmpty(getv(nf, `fee_segs.${fk}.downgrade`)) && (
            <div class="fc-ss resp-gap-2">
              <div class="fr-sc resp-gap-2 border-b border-slate-400">
                <p className="w-[6rem] text-left">Mode:</p>
                <F_OptionInput
                  {...{
                    ...inpargs,
                    path: `fee_segs.${fk}.downgrade.mode`,
                    type: "option",
                    options: downgrade_modes,
                  }}
                />
              </div>

              <div class="fr-sc resp-gap-2 border-b border-slate-400">
                <p className="w-[6rem] text-left">Req:</p>
                <F_Input
                  {...{
                    ...inpargs,
                    path: `fee_segs.${fk}.downgrade.req`,
                    type: "n",
                    contprops: { className: "w-[4rem]" },
                    inpprops: { className: "w-full" },
                  }}
                />
              </div>

              <div class="fr-sc resp-gap-2 border-b border-slate-400">
                <p className="w-[6rem] text-left">To:</p>
                <F_OptionInput
                  {...{
                    ...inpargs,
                    path: `fee_segs.${fk}.downgrade.to`,
                    type: "option",
                    options: getv(ext, "active_feekeys"),
                  }}
                />
              </div>
            </div>
          )}
        </td>
      </tr>

      {format == "rounds" && (
        <>
          <tr>
            <td></td>
            <td colSpan={11}>
              <table className="w-max thintdrowp4-table">
                <tr className="">
                  <td>
                    <div className="fr-sc resp-gap-1">
                      <span className="text-acc0">Rounds</span>
                      <span className="text-white">
                        [{_.values(getv(nf, k) ?? {}).length || 0}]
                      </span>

                      <Tag
                        onClick={() => {
                          let n = _.values(getv(nf, k) ?? {}).length ?? 0;
                          let new_rounds_conf = {
                            ...(getv(nf, k) || {}),
                            [n + 1]: {},
                          };
                          set_state_ob(nf, set_nf, k, new_rounds_conf);
                        }}
                        className="fc-cc p-2 rounded-full bg-green-500 w-max mx-auto"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </Tag>
                    </div>
                  </td>

                  <td>total in</td>
                  <td>in 1 race</td>
                  <td>no of races</td>
                  <td>payout</td>
                  <td>cb</td>
                </tr>

                {_.entries(getv(nf, `${k}`) ?? {}).map(([ridx, rconf]) => {
                  return (
                    <tr>
                      <td>
                        <div className="fr-sc resp-gap-1">
                          <span>#{ridx}</span>
                          <Tag
                            className="fc-cc p-2 bg-red-500 rounded-full w-max mx-auto"
                            onClick={() => {
                              let nrounds_conf = _.clone(getv(nf, `${k}`));
                              delete nrounds_conf[ridx];
                              nrounds_conf = _.chain(nrounds_conf)
                                .values()
                                .map((rou, i) => {
                                  return [i + 1, rou];
                                })
                                .fromPairs()
                                .value();
                              set_state_ob(nf, set_nf, `${k}`, nrounds_conf);
                            }}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Tag>
                        </div>
                      </td>
                      <td>
                        <F_Input
                          {...inpargs}
                          path={`${k}.${ridx}.tot_in`}
                          type="n"
                          contprops={{ className: "w-[4rem]" }}
                        />
                      </td>
                      <td>
                        <F_Input
                          {...inpargs}
                          path={`${k}.${ridx}.in`}
                          type="n"
                          contprops={{ className: "w-[4rem]" }}
                        />
                      </td>
                      <td>
                        <F_Input
                          {...inpargs}
                          path={`${k}.${ridx}.n`}
                          type="n"
                          contprops={{ className: "w-[4rem]" }}
                        />
                      </td>
                      <td>
                        <div className="fc-ss">
                          <F_Input
                            {...inpargs}
                            path={`${k}.${ridx}.payout`}
                            contprops={{ className: "w-[10rem]" }}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="fc-ss">
                          <F_Input
                            {...inpargs}
                            path={`${k}.${ridx}.cb`}
                            contprops={{ className: "w-[4rem]" }}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </td>
          </tr>
        </>
      )}

      <tr className="">
        <td colSpan={12}>
          <div class="h-[0.1rem] w-full bg-acc0"></div>
        </td>
      </tr>
    </>
  );
};

const fee_txt_fn = (fee) => {
  return fee.endsWith("usd")
    ? [`${fee.slice(0, -3).replace("_", ".")} USD`, 2]
    : fee == "xchampion_leader"
      ? ["XChampion", 3]
      : [_.capitalize(fee), 3];
};

export const ChallengePage = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const [tab, set_tab] = useState(psearch.tab ?? "info");

  const { id } = useParams();
  const [qo_doc, qo_leader] = useQueries([
    q_admin_challenge_doc({ id }, { enabled: id != "new" }),
    q_admin_challenge_leader({ id }, { enabled: id != "new" }),
  ]);

  const [nf, set_nf] = useState(id == "new" ? {} : undefined);
  const f = useMemo(() => {
    if (qissuccesss(qo_doc)) {
      let f = getv(qo_doc, "data.result") || {};
      if (nf === undefined) set_nf(f);
      return f;
    }
    return {};
  }, [qo_doc.dataUpdatedAt]);

  useEffect(() => {
    upd_psearch({ ...psearch, tab });
  }, [tab]);

  const fcon = {
    nf,
    set_nf,

    id,
    tab,
    set_tab,
    qo_doc,
    qo_leader,
  };

  return (
    <ChallengeContext.Provider value={fcon}>
      <Helmet>
        <title>{f?.name ?? id} || Challenges || Admin</title>
      </Helmet>
      <div className="h-page">
        <div className="max-w-[98vw] w-[80rem] mx-auto">
          <div className="h-[2rem]"></div>

          {((qissuccesss(qo_doc) && qissuccesss(qo_leader)) || id == "new") && (
            <>
              <ControlRow />

              <div className="fr-sc resp-gap-2">
                {["info", "leaderboard"].map((_st) => {
                  return (
                    <Tag
                      onClick={() => set_tab(_st)}
                      className={twMerge(
                        "resp-text--1 font-digi transition duration-300",
                        _st == tab ? "bg-acc0/50" : "bg-transparent text-white",
                      )}
                    >
                      {_.capitalize(_st)}
                    </Tag>
                  );
                })}
                <div class="flex-1"></div>
                <Tag
                  redirect={`https://fbike.dnaracing.run/challenge/${id}`}
                  className="text-acc0"
                >
                  <span class="resp-text-2">
                    <FontAwesomeIcon icon={faLink} />
                  </span>
                </Tag>
              </div>

              {tab == "info" && <InfoTab />}

              <div className="h-[2rem]"></div>
            </>
          )}
        </div>
      </div>
    </ChallengeContext.Provider>
  );
};
